import { AxiosError } from "axios";
import { format } from "date-fns";
import {
  ErrorMessage,
  ErrorMessageProps,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
} from "formik";
import { useRouter } from "next/router";
import React, { useState } from "react";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";
import {
  createPortfolioOrganization,
  deletePortfolioOrganization,
  getter,
  updatePortfolioOrganization,
} from "../api";
import {
  FundAutocompleteResult,
  GroupAutocompleteResult,
  OrganizationAutocompleteResult,
  OrganizationStub,
  PaginatedList,
  PortfolioOrganizationItem,
  Success,
  TagAutocompleteResult,
  UserAutocompleteResult,
} from "../api/types";
import { useCurrentUser } from "./AuthProvider";
import { Button } from "./Button";
import { ConfirmationModal } from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";
import FormCompanySelectField from "./Form/FormCompanySelectField";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import GroupSelectAll from "./Select/GroupSelectAll";
import Select from "./Select/Select";
import UserSelect from "./Select/UserSelect";
import { parseAxiosError } from "./utils";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

interface FieldErrorProps extends ErrorMessageProps {}

const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => <div className="mt-1 text-red-500">{error}</div>}
  </ErrorMessage>
);

const healthOptions = [
  { value: "red", label: "Red" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
];

const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

interface PortfolioFormValues {
  organization: OrganizationAutocompleteResult | null;
  owner: UserAutocompleteResult | null;
  group: GroupAutocompleteResult | null;
  health: { value: string; label: string } | null;
  currency: { value: string; label: string } | null;
  fiscal_start_month: { value: number; label: string } | null;
  tags: TagAutocompleteResult[];
  exited: boolean;
  exited_at: string | null;
  exited_description: string;
  investment_funds: FundAutocompleteResult[];
}

function portfolioToFormValues(
  portfolio: PortfolioOrganizationItem
): PortfolioFormValues {
  return {
    organization: {
      label: portfolio.organization.name,
      value: portfolio.organization.id,
      domain: portfolio.organization.domain,
      image_url: portfolio.organization.image_url,
      description: portfolio.organization.description,
    },
    owner: portfolio.owner
      ? {
          value: portfolio.owner.id,
          email: portfolio.owner.email,
          label: portfolio.owner.name,
        }
      : null,
    group: {
      label: portfolio.group.name,
      value: portfolio.group.id,
    },
    health: portfolio.health
      ? {
          value: portfolio.health,
          label:
            portfolio.health.charAt(0).toUpperCase() +
            portfolio.health.slice(1),
        }
      : null,
    currency: {
      label: portfolio.currency,
      value: portfolio.currency,
    },
    fiscal_start_month: {
      label: monthOptions[portfolio.fiscal_start_month - 1].label,
      value: portfolio.fiscal_start_month,
    },
    tags: portfolio.tags.map((tag) => ({
      label: tag.name,
      value: tag.id,
    })),
    investment_funds: portfolio.investment_fund?.map((fund) => ({
      label: fund.name,
      value: fund.id,
    })),
    exited: portfolio.exited_at !== null,
    exited_at: portfolio.exited_at
      ? format(new Date(portfolio.exited_at), "yyyy-MM-dd")
      : null,
    exited_description: portfolio.exited_description || "",
  };
}

type PortfolioFormProps = {
  portfolio?: PortfolioOrganizationItem;
  pk?: string;
  defaultOrganization?: OrganizationStub;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  mutate?: KeyedMutator<Success<PaginatedList<PortfolioOrganizationItem>>>;
  setHidden?: boolean;
  success?: (portfolio: PortfolioOrganizationItem) => void;
};

const PortfolioForm = ({
  portfolio,
  pk,
  defaultOrganization,
  setIsOpen,
  mutate,
  setHidden,
  success,
}: PortfolioFormProps) => {
  const { data: selectedGroup } =
    useSWR<GroupAutocompleteResult>(GLOBAL_GROUP_KEY);
  const { user: currentUser } = useCurrentUser();
  const initialOwner = {
    label: currentUser.name,
    value: currentUser.id,
    email: currentUser.email,
    image_url: currentUser.image_url,
  };
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cache } = useSWRConfig();
  const { data: currencyData } = useSWR<any>(
    "/api/portfolio/financial/currencies",
    getter
  );
  let currencyArr: GroupAutocompleteResult[] = [];
  for (let i = 0; i < currencyData?.data?.length; i++) {
    let itemCur = {
      value: 0,
      label: "",
    };
    itemCur.value = i;
    itemCur.label = currencyData.data[i];
    currencyArr.push(itemCur as any);
  }

  const emptyForm: PortfolioFormValues = {
    organization: defaultOrganization
      ? {
          label: defaultOrganization.name,
          value: defaultOrganization.id,
          domain: defaultOrganization.domain,
          image_url: defaultOrganization.image_url,
          description: defaultOrganization.description,
        }
      : null,
    owner: initialOwner,
    group: selectedGroup ?? null,
    health: { value: "green", label: "Green" },
    currency: { value: "254", label: "USD" },
    fiscal_start_month: { value: 1, label: "January" },
    tags: [],
    exited: false,
    exited_at: null,
    exited_description: "",
    investment_funds: [],
  };

  const initialValues = portfolio
    ? portfolioToFormValues(portfolio)
    : emptyForm;

  const clickDeletePortfolioOrganization = (pk: string | undefined) => {
    if (pk) {
      setIsLoading(true);
      deletePortfolioOrganization(pk)
        .then((response) => {
          if (response.data.success) {
            setDeleted(true);
          }
        })
        .catch((error) => {
          setErrorMessage(parseAxiosError(error));
          setErrorModal(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSubmit = (values: PortfolioFormValues) => {
    const payLoad = {
      organization: values.organization?.value as number,
      owner: values.owner?.value as number,
      group: values.group?.value as number,
      health: values.health?.value,
      currency: values.currency?.label,
      fiscal_start_month: values.fiscal_start_month?.value,
      tags: values.tags.map((tag) => tag.label),
      exited_at: values.exited ? values.exited_at : null,
      exited_description: values.exited ? values.exited_description : "",
      investment_funds: values.investment_funds.map((fund) => fund.value),
    };
    const submitFunction = pk
      ? updatePortfolioOrganization(payLoad, pk)
      : createPortfolioOrganization(payLoad);
    return submitFunction
      .then((res) => {
        if (res.data) {
          const dataPortfolio = res.data.data;
          if (mutate) {
            mutate();
          }
          if (setIsOpen) {
            setIsOpen(false);
          }
          if (success) {
            success(dataPortfolio);
          }
        }
      })
      .catch((err: AxiosError) => {
        setError(err);
        switch (err.response?.status) {
          case 409:
            setErrorMessage("Organization is already in Portfolio");
            break;
          case 403:
            setErrorMessage(parseAxiosError(err));
            setErrorModal(true);
            break;
          default:
            setErrorMessage(err.message);
            break;
        }
        setOpen(true);
      });
  };

  const validate = (values: PortfolioFormValues) => {
    let errors: FormikErrors<PortfolioFormValues> = {};
    if (!values.organization) {
      errors.organization = "is required";
    }
    if (!values.owner) {
      errors.owner = "is required";
    }
    if (!values.group) {
      errors.group = "is required";
    }
    if (
      values.exited &&
      (values.exited_at === null || !values.exited_description)
    ) {
      errors.exited_description = "Exit description is required";
    }
    return errors;
  };

  return (
    <div>
      {error && <p className="text-red-500">{errorMessage}</p>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setErrors,
          isSubmitting,
          values,
        }) => (
          <Form>
            <div className="grid gap-y-2 gap-x-2 relative text-xs">
              <div
                className={`mt-2 flex ml-4 ${
                  setHidden === true ? "hidden" : "block"
                }`}
              >
                <label className="mt-2 mr-4 font-semibold w-28 text-left">
                  Organization
                </label>
                <div className="w-full ml-3 sm:ml-6 sm:mr-6">
                  <div className="relative w-[70%]">
                    <Field
                      as={FormCompanySelectField}
                      name="organization"
                      withNinja={false}
                      flexible={true}
                      placeholder={"Search Company"}
                      onChange={(sourceValue: any) => {
                        setFieldValue("organization", sourceValue);
                        if (error) {
                          setError(undefined);
                        }
                      }}
                    />
                    <FieldError name="organization" />
                  </div>
                </div>
              </div>
              <FieldError name="organizations" />
              <div className="mt-4 flex ml-4">
                <label className="mt-2 font-semibold mr-4 w-28 text-left">
                  Fund
                </label>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="investment_funds">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <AutocompleteMultiselect
                            id="fundField"
                            autocompleteEndpoint="/api/people_map/autocomplete/investment_funds"
                            selected={field.value}
                            onChange={(newValue) =>
                              setFieldValue(field.name, newValue)
                            }
                            creatable={false}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <FieldError name="investment_funds" />
                </div>
              </div>
              <div className="mt-4 ml-4 flex justify-center">
                <label className="mt-2 font-semibold mr-4 w-28 text-left flex">
                  Owner
                  <div className="text-red-600 text-sm font-semibold">*</div>
                </label>

                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="owner" className="justify-center mr-7">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <UserSelect
                            id="owner"
                            {...field}
                            onChange={(newValue) =>
                              setFieldValue(field.name, newValue)
                            }
                          />
                        </div>
                      )}
                    </Field>
                    {/* <span className="ml-2 h-4 w-4 text-red-600 text-3xl absolute top-2 -right-1">
                      *
                    </span> */}
                  </div>
                  <FieldError name="owner" />
                </div>
              </div>
              <div className="mt-4 ml-4  flex justify-center">
                <label className="mt-2 font-semibold mr-4 w-28 text-left flex flex-row">
                  Group
                  <div className="text-red-600 text-sm font-semibold">*</div>
                </label>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="group">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <GroupSelectAll
                            id="groupsField"
                            {...field}
                            onChange={(newValue: any) => {
                              setFieldValue(field.name, newValue);
                              if (error) {
                                setError(undefined);
                              }
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <FieldError name="group" />
                </div>
              </div>
              <div className="mt-4 ml-4  flex justify-center">
                <label className="mt-2 font-semibold mr-4 w-28 text-left flex flex-row">
                  Health
                  <div className="text-red-600 text-sm font-semibold">*</div>
                </label>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="health">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <Select
                            value={field.value}
                            options={healthOptions}
                            onChange={(newValue: any) => {
                              setFieldValue(field.name, newValue);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <FieldError name="group" />
                </div>
              </div>
              <div className="mt-4 flex ml-4">
                <label className="mt-2 font-semibold mr-4 w-28 text-left flex flex-row">
                  Currency
                  <div className="text-red-600 text-sm font-semibold">*</div>
                </label>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="currency">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <Select
                            value={field.value}
                            options={currencyArr}
                            onChange={(newValue: any) => {
                              setFieldValue(field.name, newValue);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <FieldError name="curency" />
                </div>
              </div>
              <div className="mt-4 flex ml-4">
                <div className="text-left">
                  <label className="inline-flex flex-wrap font-semibold mb-1 md:mb-0 pr-4">
                    <span className="w-full">
                      Fiscal Start Month
                      <span className="text-red-600 text-sm font-semibold">
                        *
                      </span>
                    </span>
                  </label>
                </div>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="fiscal_start_month">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <Select
                            value={field.value}
                            options={monthOptions}
                            onChange={(newValue: any) => {
                              setFieldValue(field.name, newValue);
                            }}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <FieldError name="fiscal_start_month" />
                </div>
              </div>
              <div className="mt-4 flex ml-4">
                <label className="mt-2 font-semibold mr-4 w-28 text-left">
                  Tags
                </label>
                <div className="w-full ml-6 mr-6">
                  <div className="relative">
                    <Field name="tags">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <div className="mr-7">
                          <AutocompleteMultiselect
                            id="tagsField"
                            autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                            selected={field.value}
                            onChange={(newValue) =>
                              setFieldValue(field.name, newValue)
                            }
                            creatable
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <FieldError name="tags" />
                </div>
              </div>
              {portfolio && (
                <>
                  <div className="mt-4 flex ml-4">
                    <label className="font-semibold mr-4 w-28 text-left">
                      Exited
                    </label>
                    <div className="w-full ml-6 mr-6">
                      <div className="relative">
                        <Field name="exited">
                          {({ field, form: { setFieldValue } }: FieldProps) => (
                            <div className="mr-7">
                              <input
                                type="checkbox"
                                id="exited"
                                checked={values.exited}
                                className="w-5 h-5 rounded-full ml-1 text-red-600 focus:ring-transparent text-xs cursor-pointer"
                                {...field}
                                onChange={(e) => {
                                  setFieldValue(
                                    field.name,
                                    e.currentTarget.checked
                                  );
                                  setFieldValue(
                                    "exited_at",
                                    e.currentTarget.checked
                                      ? format(new Date(), "yyyy-MM-dd")
                                      : null
                                  );
                                }}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  {values.exited && (
                    <>
                      <div className="mt-4 flex ml-4 items-center">
                        <label className="font-semibold mr-4 w-28 text-left">
                          Exit Date
                        </label>
                        <div className="w-full ml-6 mr-6">
                          <div className="flex flex-row items-center relative">
                            <Field name="exited_at">
                              {({ field }: FieldProps) => (
                                <input
                                  id="exited_at"
                                  type="date"
                                  className="rounded-md border-gray-300 text-xs"
                                  {...field}
                                />
                              )}
                            </Field>
                            <span className="ml-1 h-8 w-8 text-red-600 text-3xl">
                              *
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex ml-4">
                        <label className="mt-2 font-semibold mr-4 w-28 text-left">
                          Exit Description
                        </label>
                        <div className="w-full ml-6 mr-6">
                          <div className="flex flex-row items-center relative">
                            <Field
                              name="exited_description"
                              type="text"
                              id="exited_description"
                              placeholder="Reason"
                              className="rounded-md text-xs border-gray-300 w-[96%]"
                            />
                            <span className="ml-1 h-8 w-8 text-red-600  text-3xl">
                              *
                            </span>
                          </div>
                          <FieldError name="exited_description" />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-row justify-end mt-6 space-x-4 mr-5">
              {pk && (
                <Button
                  buttonStyle={"danger"}
                  hidden={setHidden}
                  onClick={() => setConfirmDelete(true)}
                >
                  Remove
                </Button>
              )}
              <Button
                isLoading={isSubmitting}
                loadingText="Saving..."
                buttonStyle="primary"
              >
                {setHidden === true ? "Save" : "Submit"}
              </Button>
            </div>
            <ConfirmationModal
              open={confirmDelete}
              title="Remove Organization from Portfolio?"
              subtitle="This action cannot be undone"
              succeed={deleted}
              succeedMessage={`You deleted organization.`}
              processing={isLoading}
              processingText={"Deleting..."}
              submitButtonText={"Delete"}
              onClose={() => {
                if (deleted && mutate) {
                  mutate();
                }
                if (setIsOpen) {
                  setIsOpen(false);
                }
                setConfirmDelete(false);
              }}
              onSubmit={() => clickDeletePortfolioOrganization(pk)}
            />
          </Form>
        )}
      </Formik>
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PortfolioForm;
