import { AxiosError } from "axios";
import { Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { createVpcFund, getter, updatedVpcFund } from "../../api";
import {
  FundFormValues,
  Success,
  VpcFundParams,
  VpcFundResponse,
} from "../../api/types";
import ErrorModal from "../ErrorModal";
import FormTextField from "../Form/FormTextField";
import FormInvestorOrganizationSelectedField from "../Select/FormInvestorOrganizationSelectedField";
import FormVMFSelectedField from "../Select/FormVMFSelectedField";
import Spinner from "../Spinner";
import { parseAxiosError } from "../utils";
import SpinnerCustom from "../SpinnerCustom";

type FundProps = {
  title: string;
  pk?: number;
  callback?: (data: any) => void;
};

const emptyForm: FundFormValues = {
  id: undefined,
  investor: undefined,
  fund_name: "",
  parent_fund: undefined,
};

const VertexPortfolioFundForm = ({ title, pk, callback }: FundProps) => {
  const { data: fundData, isValidating } = useSWR<Success<VpcFundResponse>>(
    pk ? `/api/people_map/funds/${pk}` : null,
    getter,
    { revalidateOnFocus: false }
  );

  const [initialValues, setInitialValues] = useState<FundFormValues>(emptyForm);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorModal, setErrorModal] = useState<boolean>(false);

  useEffect(() => {
    if (fundData && fundData.data) {
      setInitialValues({
        id: fundData.data.id,
        investor: fundData.data.organization
          ? {
              value: fundData.data.organization.id,
              label: fundData.data.organization.name,
              domain: fundData.data.organization.domain,
              image_url: fundData.data.organization.image_url,
            }
          : undefined,
        fund_name: fundData.data.name,
        parent_fund: fundData.data.lp_funds?.length
          ? fundData.data.lp_funds.map((fund: any) => {
              return {
                value: fund.investor_fund.id,
                label: fund.investor_fund.name,
              };
            })
          : undefined,
      });
    }
  }, [fundData]);

  const validate = (values: FundFormValues) => {
    let errors: FormikErrors<FundFormValues> = {};

    if (!values.fund_name) {
      errors.fund_name = "Fund Name is required";
    }
    if (!values.investor) {
      errors.investor = "Investor is required";
    }
    return errors;
  };

  const handleSubmit = (values: FundFormValues) => {
    setIsLoading(true);

    const payload: VpcFundParams = {
      name: values.fund_name,
      organization_id: values.investor?.value,
      lp_fund_ids: values.parent_fund?.length
        ? values.parent_fund?.map((fund) => fund.value)
        : [],
    };
    const apiCaller = pk ? updatedVpcFund(payload, pk) : createVpcFund(payload);
    apiCaller
      .then((response: any) => {
        if (response.success) {
          callback && callback(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (
          error.response?.data?.error?.message === "Fund name already exists"
        ) {
          setErrorMessage("Please change the fund name.");
        } else {
          setErrorMessage(parseAxiosError(error));
        }
        setErrorModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isValidating ? (
        <SpinnerCustom />
      ) : (
        <Formik<FundFormValues>
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={initialValues}
          validate={validate}
        >
          {({ values, errors, touched, resetForm }) => (
            <div>
              <div className="flex space-x-4 items-center">
                <div className="h-8 w-2 bg-blue-menu rounded-full" />
                <h2 className="font-bold text-base md:text-2xl tracking-wide mt-3 mb-3">
                  {title}
                </h2>
              </div>
              <Form className="flex flex-col gap-y-4 mt-5">
                <Field
                  as={FormInvestorOrganizationSelectedField}
                  name="investor"
                  label="Investor"
                  errors={errors.investor}
                  touched={touched.investor}
                  required={true}
                  isMulti={false}
                />
                <Field
                  as={FormTextField}
                  name="fund_name"
                  label="Fund Name"
                  errors={errors.fund_name}
                  touched={touched.fund_name}
                  required={true}
                />
                <Field
                  as={FormVMFSelectedField}
                  name="parent_fund"
                  label="Master Fund"
                  errors={errors.parent_fund}
                  touched={touched.parent_fund}
                  required={false}
                  fundType="parent"
                />
                <div className="flex flex-row gap-x-3 justify-end w-full md:w-11/12 my-8">
                  <button
                    type="button"
                    className="btn-secondary"
                    onClick={() => {
                      resetForm({ values: emptyForm });
                    }}
                  >
                    Clear Form
                  </button>
                  <button className="btn-primary">
                    {isLoading ? (
                      <div className="flex flex-row items-center text-white gap-x-2">
                        <div>
                          <Spinner className="w-4 h-4 text-white" />
                        </div>
                        <div>Submitting</div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </>
  );
};
export default VertexPortfolioFundForm;
