import {
  ArchiveBoxIcon,
  ClipboardIcon,
  FireIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";
import ActivityIcon from "./Icons/ActivityIcon";
import { Llama } from "./Llama";
import ProfileMenu from "./ProfileMenu";
import { classNames } from "./utils";
import { setNavigation } from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

type GlobalGroupProps = {
  label: string;
  value: string;
};

const DropDownMenu = () => {
  return <ProfileMenu navOpen={true} />;
};

type SideBarProps = {
  closeSidebar: () => void;
};

const Sidebar = ({ closeSidebar }: SideBarProps) => {
  const { data: selectedGroup } = useSWR<GlobalGroupProps>(GLOBAL_GROUP_KEY);

  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  var updatedNavigation = setNavigation("default");

  if (selectedGroup) {
    updatedNavigation = setNavigation(selectedGroup.label);
  }

  const current = updatedNavigation.find(
    (item) =>
      item?.href === router.pathname || item.prefixes?.includes(router.pathname)
  );

  return (
    <div className="h-full flex flex-col justify-between py-3 bg-blue-menu sm:max-w lg:max-w-xs">
      <img
        src="/labs-icon.png"
        alt="Samurai logo"
        className="pt-2 w-[102px] h-[60px] ml-[30px] mb-5 mt-2 cursor-pointer"
        onClick={() =>
          router.push(
            envString === "vvseai" || envString === "vvhc" ? "/dealflow" : "/"
          )
        }
      />
      <div className="flex-grow flex flex-col no-scrollbar overflow-x-auto">
        <div className="justify-between">
          <nav className="flex-1 space-y-2 lg:space-y-6">
            {updatedNavigation.map((item) => (
              <Link
                href={item.href}
                key={item.key}
                className={classNames(
                  item.key === current?.key
                    ? "border-white text-white"
                    : "border-transparent text-gray-300",
                  "flex items-center px-3 py-2 font-medium border-l-4 text-xs lg:text-sm tracking-wide font-light"
                )}
                onClick={closeSidebar}
              >
                <div className="mr-6">
                  <item.icon
                    className="flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <span>{item.name}</span>
              </Link>
            ))}
          </nav>
          <div>
            <div className="flex items-baseline justify-left text-left mt-6 text-xs px-3">
              <div className="cursor-pointer flex flex-1">
                <div
                  className="group relative flex flex-col items-center w-full"
                  onClick={() => {
                    window.sessionStorage.setItem("showValStatus", "true");
                    window.dispatchEvent(new Event("storage"));
                    closeSidebar();
                  }}
                >
                  <Llama
                    customClass="w-6 h-6 mr-2"
                    svgCustomClass="-mt-1"
                    color="#fff"
                  />
                  <div className="text-3xs text-white ml-1 text-center">
                    Ask Val
                  </div>
                </div>
              </div>
              <Link href={`/collab`} className="flex flex-1">
                <div className="relative group flex flex-col items-center w-full h-[41px] justify-end">
                  <div>
                    <ArchiveBoxIcon className="h-6 w-6 stroke-1 text-white" />
                  </div>
                  <div className="text-3xs text-white">Collab</div>
                </div>
              </Link>
              <Link href={`/tasks`} className="flex flex-1">
                <div className="relative group flex flex-col items-center w-full h-[41px] justify-end">
                  <ClipboardIcon className="h-6 w-6 stroke-1 text-white" />
                  <div className="text-3xs text-white">Tasks</div>
                </div>
              </Link>
              <Link href={`/activity_logs`} className="flex flex-1">
                <div className="relative group flex flex-col items-center w-full h-[41px] justify-end">
                  <ActivityIcon className="h-6 w-6 stroke-1 fill-white" />
                  <div className="text-3xs text-white">Activity</div>
                </div>
              </Link>
              <Link href={`/help`} className="flex flex-1">
                <div className="relative group flex flex-col items-center w-full h-[41px] justify-end">
                  <QuestionMarkCircleIcon className="h-6 w-6 stroke-1 text-gray-500 text-white" />
                  <div className="text-3xs text-white">Help</div>
                </div>
              </Link>
              <Link href={`/changelog`} className="flex flex-1">
                <div className="relative group flex flex-col items-center w-full h-[41px] justify-end">
                  <FireIcon className="h-6 w-6 stroke-1 text-gray-500 text-white shrink-0" />
                  <div className="text-3xs text-white text-center">
                    Change Log
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-left text-left mt-6 ml-6 text-xs">
            <DropDownMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
