import Spinner from "./Spinner";

export default function Splash() {
  return (
    <div className="grid grid-rows-[auto] justify-items-center items-center h-screen bg-center bg-cover bg-no-repeat bg-blue-600 bg-wave">
      <div className="mt-40">
        <img
          src={"/labs-icon.png"}
          className="h-40"
          alt="labs-logo"
          loading="lazy"
        />
      </div>
      <div className="mb-64">
        <Spinner className="w-20 h-20 text-blue-600 border-[5px]" />
      </div>
    </div>
  );
}
