import axios from "axios";
import { useRouter } from "next/router";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  signIn as doSignIn,
  signOut as doSignOut,
  getMe,
  getToken,
  instance,
  SignInParams,
} from "../api";
import { AuthResponse } from "../api/types";
import Splash from "./Splash";

const RHOMBUS_BEARER_TOKEN_KEY = "rhombusBearerToken";

const getBearerToken: () => string = () => {
  const token = window.localStorage.getItem(RHOMBUS_BEARER_TOKEN_KEY);
  return token || "";
};

const setBearerToken = (token: string) => {
  window.localStorage.setItem(RHOMBUS_BEARER_TOKEN_KEY, token);
};

export interface CurrentUser {
  id: number;
  email: string;
  name: string;
  firstName: string;
  image_url: string;
  feature_group: string;
}

export type Session =
  | {
      state: "loading";
    }
  | { state: "authenticating" }
  | { state: "unauthenticated" }
  | {
      state: "authenticated";
      user: CurrentUser;
    };

export interface Auth {
  session: Session;
  signIn: (args: SignInParams) => void;
  signOut: () => void;
  refresh: () => void;
}

export const AuthContext = createContext<Auth>({
  session: { state: "loading" },
  signIn(): void {},
  signOut(): void {},
  refresh(): void {},
});

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [session, setSession] = useState<Session>({
    state: "loading",
  });

  const initSession = (auth: AuthResponse) => {
    const { id, email, first_name, name, image_url, feature_group, token } =
      auth;
    setBearerToken(token);
    instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
    setSession({
      state: "authenticated",
      user: {
        id,
        email,
        name,
        firstName: first_name,
        image_url: image_url,
        feature_group: feature_group,
      },
    });
  };

  const clearSession = () => {
    doSignOut()
      .catch(function (error) {
        console.log(error);
      })
      .then(() => {
        setBearerToken("");
        delete instance.defaults.headers.common["authorization"];
        setSession({ state: "unauthenticated" });
      });
  };

  // This first effect runs only once in the application lifecycle.
  // It adds an interceptor to set the auth state back to unauthenticated
  // whenever we receive a 401 response.
  useEffect(() => {
    const handle = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!axios.isCancel(error) && error.response.status === 401) {
          setSession({
            state: "unauthenticated",
          });
        }
        return Promise.reject(error);
      }
    );
    return () => instance.interceptors.response.eject(handle);
  }, []);

  // This second effect runs when the auth state changes.
  // It makes a request to the /api/users/auth endpoint if the auth state is loading.
  // If the response is a 401, the interceptor set up in the previous effect
  // will set the auth state to unauthenticated.
  useEffect(() => {
    if (session.state !== "loading") {
      return;
    }
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${getBearerToken()}`;
    const query = new URLSearchParams(window.location.search);
    const token_grant = query.get("token_grant") || "";
    getToken(token_grant).then(
      (response) => {
        initSession(response.data);
      },
      // We need to handle the error here, because Next.js complains about
      // runtime errors in development.
      () => {}
    );
  }, [session.state]);

  const router = useRouter();

  // 1. If we are authenticated and on the sign-in page, go to the home page
  // 2. If we are not authenticated and not on the sign-in page, go to the sign-in page
  // 3. If the auth state is loading, show a splash screen
  // 4. Otherwise, render children normally
  switch (session.state) {
    case "authenticated":
      if (window.location.pathname.startsWith("/signin")) {
        // noinspection JSIgnoredPromiseFromCall
        router.replace("/");
        return <Splash />;
      }
      break;
    case "unauthenticated":
      if (!window.location.pathname.startsWith("/signin")) {
        // noinspection JSIgnoredPromiseFromCall
        router.replace("/signin");
        return <Splash />;
      }
      break;
    default:
      return <Splash />;
  }

  const signIn = async (args: SignInParams) => {
    setSession({ state: "authenticating" });
    const authResponse = await doSignIn(args);
    if (authResponse.status === 200) {
      initSession(authResponse.data);
    } else {
      setSession({ state: "unauthenticated" });
    }
  };

  const signOut = () => {
    clearSession();
  };

  const refresh = async () => {
    const authResponse = await getMe();
    if (authResponse.status === 200) {
      initSession(authResponse.data);
    } else {
      setSession({ state: "unauthenticated" });
    }
  };

  const auth = {
    session,
    signIn,
    signOut,
    refresh,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const useCurrentUser = () => {
  const auth = useAuth();
  if (auth.session.state !== "authenticated") {
    throw Error("not signed in");
  }
  return { user: auth.session.user, refresh: auth.refresh };
};

export default AuthProvider;
