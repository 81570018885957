export const HoverTooltip = ({ content }: { content: string }) => {
  return (
    <>
      <div className="absolute z-top p-2 -top-8 -right-60 left-32 text-xs font-medium text-white bg-gray-800 rounded-lg hidden whitespace-pre-line group-hover:inline-block">
        {content}
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
      <svg
        className="absolute z-10 w-6 h-6 text-gray-800 transform top-[17px] left-[161px] -translate-x-12 -translate-y-3 fill-current stroke-current hidden group-hover:inline-block"
        width="8"
        height="8"
      >
        <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
      </svg>
    </>
  );
};
