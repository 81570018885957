type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps) => (
  <div
    className={`animate-spin inline-block ${
      className ?? "w-5 h-5"
    } border-[3px] border-current border-t-transparent rounded-full`}
    role="status"
    aria-label="loading"
  ></div>
);
export default Spinner;
