import React from "react";

interface HandshakeIcon {
  className?: SVGAnimatedString;
}

export class Handshake extends React.PureComponent<HandshakeIcon> {
  render() {
    return <span className="material-symbols-outlined ml-0.5">handshake</span>;
  }
}
