import { useField } from "formik";
import InvestorOrganizationSelectAll from "./InvestorOrganizationSelectAll";
import { classNames } from "../utils";

type InvestorOrganizationSelectedFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
};
const FormInvestorOrganizationSelectedField = ({
  name,
  required = false,
  label,
  errors,
  touched,
  isMulti = true,
}: InvestorOrganizationSelectedFieldProps) => {
  const [input, meta, helpers] = useField(name);

  const { value, checked, onBlur } = input;
  const { setValue } = helpers;

  return (
    <div className="grid md:w-11/12 w-full">
      <div className="flex">
        <label className="font-semibold text-xs" htmlFor={name}>
          {label}
        </label>
        {required ? (
          <div className="text-red-600 text-sm font-semibold">*</div>
        ) : (
          ""
        )}
      </div>
      <div
        className={classNames(
          "w-full items-center border border-1 relative h-fit rounded-md",
          touched && errors ? "border-red-500" : "border-blue-menu"
        )}
      >
        <InvestorOrganizationSelectAll
          id={name}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          isMulti={isMulti}
        />
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden"
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormInvestorOrganizationSelectedField;
