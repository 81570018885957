import { useField } from "formik";
import PersonSelect from "../Select/PersonSelect";
import { classNames } from "../utils";

type FormPersonSelectFieldProps = {
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  showAddButton?: boolean;
  customClass?: string;
  fixWidth?: boolean;
  placeholder?: string;
};
const FormPersonSelectField = ({
  name,
  required,
  label,
  errors,
  touched,
  isMulti = false,
  isClearable = false,
  showAddButton = true,
  fixWidth = false,
  customClass,
  placeholder,
}: FormPersonSelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  const { value, checked } = input;
  const { setValue } = helpers;

  const isInvalid =
    touched && touched !== undefined && errors && errors !== undefined;

  return (
    <div>
      <div className="flex items-end">
        <label className="font-semibold text-xs" htmlFor={name}>
          {label}
        </label>
        <div>
          {required ? (
            <div className="text-red-600 text-sm font-semibold">*</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={`${customClass} ${isInvalid && "border-red-500"}`}>
        <PersonSelect
          id={name}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          isMulti={isMulti}
          isClearable={isClearable}
          showAddButton={showAddButton}
          fullWidth={true}
          fixWidth={fixWidth}
          placeholder={placeholder}
        />
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden"
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormPersonSelectField;
