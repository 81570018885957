import { useField } from "formik";
import IndustriesSelect from "../Select/IndustriesSelect";
import { classNames } from "../utils";

type FormIndustriesSelectFieldProps = {
  id: string;
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
};
const FormIndustriesSelectField = ({
  id,
  name,
  required,
  label,
  errors,
  touched,
  isMulti = false,
}: FormIndustriesSelectFieldProps) => {
  const [input, , helpers] = useField(name);

  const { value, onBlur } = input;
  const { setValue } = helpers;

  return (
    <div className="grid md:w-11/12 w-full">
      <div className="flex">
        <label className="font-semibold text-xs" htmlFor={name}>
          {label}
        </label>
        <div className="text-red-600 text-sm font-semibold">*</div>
      </div>
      <div
        className={classNames(
          "w-full items-center relative h-fit rounded-md",
          touched && errors
            ? "border border-1 border-red-500"
            : "border border-1 border-blue-menu"
        )}
      >
        <IndustriesSelect
          id={id}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          isMulti={isMulti}
        />
      </div>

      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden"
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormIndustriesSelectField;
