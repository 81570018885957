import { Menu, Transition } from "@headlessui/react";
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  DocumentIcon,
  PaperAirplaneIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import router from "next/router";
import { Fragment, useEffect, useRef } from "react";

type DropDownProps = {
  showInHeader?: boolean;
  openCreate?: boolean;
  setOpenCreate?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CLOSE_CREATE_DROPDOWN_DELAY = 100;

const CreateDropDownMenu = ({
  showInHeader = false,
  openCreate,
  setOpenCreate,
}: DropDownProps) => {
  const createDropdownMenuRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        createDropdownMenuRef.current &&
        !createDropdownMenuRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setOpenCreate && setOpenCreate(false);
        }, CLOSE_CREATE_DROPDOWN_DELAY);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [createDropdownMenuRef]);

  return (
    <div
      className={`flex ${
        showInHeader ? "absolute top-full w-full right-0" : ""
      }`}
    >
      {!showInHeader && (
        <button
          className="rounded-l-lg bg-blue-menu text-white items-center text-2xs md:text-xs font-medium px-3 w-max"
          onClick={() => router.push("/opportunity/new")}
        >
          Create Opportunity
        </button>
      )}
      <Menu
        as="div"
        className={`z-top flex rounded-r-lg bg-blue-menu ${
          !showInHeader ? "border-white border-l-2 px-2" : ""
        } text-white items-center`}
      >
        {({ open }) => (
          <>
            <Menu.Button
              className="flex items-left"
              id={`create-dropdown-menu-${showInHeader ? "mobile" : "desktop"}`}
            >
              {!showInHeader && (
                <ChevronDownIcon className="h-4 w-4 fill-white text-white" />
              )}
            </Menu.Button>
            <Transition
              show={openCreate || open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              ref={createDropdownMenuRef}
            >
              <Menu.Items
                className={`absolute ${
                  !showInHeader
                    ? "top-[120px] md:top-[60px] right-[30px] md:right-[267px]"
                    : "top-0 right-5 mt-2"
                } divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          router.push("/opportunity/new");
                          setOpenCreate && setOpenCreate(false);
                        }}
                      >
                        <SparklesIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Opportunity
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          router.push("/people/create");
                          setOpenCreate && setOpenCreate(false);
                        }}
                      >
                        <UsersIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Contact
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          router.push("/organizations/create");
                          setOpenCreate && setOpenCreate(false);
                        }}
                      >
                        <BuildingOfficeIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Organization
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          router.push("/meeting_notes/create");
                          setOpenCreate && setOpenCreate(false);
                        }}
                      >
                        <DocumentIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Note
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-blue-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        onClick={() => {
                          router.push("/gtm_project/create");
                          setOpenCreate && setOpenCreate(false);
                        }}
                      >
                        <PaperAirplaneIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create GTM Project
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
export default CreateDropDownMenu;
