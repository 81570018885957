import { AxiosError } from "axios";
import { Field, Form, Formik, FormikErrors } from "formik";
import { useState } from "react";
import { createTemplatePrompt, updateTemplatePrompt } from "../../api";
import { TemplatePrompt, TemplatePromptParams } from "../../api/types";
import ErrorModal from "../ErrorModal";
import FormTextAreaField from "../Form/FormTextAreaField";
import FormTextField from "../Form/FormTextField";
import Spinner from "../Spinner";
import Tooltip from "../Tooltip";
import { parseAxiosError } from "../utils";

type TemplatePromptFormValues = {
  title: string;
  prompt: string;
};

const templatePromptFormValues = (
  prompt: TemplatePrompt
): TemplatePromptFormValues => {
  return {
    title: prompt.title,
    prompt: prompt.prompt,
  };
};

type TemplatePromptFormProps = {
  agentId: number;
  templatePrompt?: TemplatePrompt;
  onSuccessed: (agent: TemplatePrompt, isCreateNew?: boolean) => void;
};

const PromptTooltip = () => {
  return (
    <>
      <p>Best practices for creating a prompt include:</p>
      <ul>
        <li>
          - Use command to instruct model what you want to achieve (E.g.,
          "Write", "Classify", "Summarize", "Translate", "Order")
        </li>
        <li>
          - Be very specific about the instruction and task you want to perform
          (E.g., Classify the text into neutral, negative or positive. )
        </li>
        <li>
          - Inform model to respond in a way if there's no answer (E.g.,Respond
          "Unsure about answer" if not sure about the answer.)
        </li>
        <li>
          - State the tool you want to use (E.g., Using the internet search)
        </li>
        <li>
          - If there are inputs, set them near to the top of the prompt
          surrounded by double curly brackets (E.g. Sector:{" "}
          {`{{ sector_name }}`}).
        </li>
        <li>
          - Specify the format of the response you wish to see (E.g. Sector:{" "}
          {"<sector>"}, Reason: {"<reason>"})
        </li>
      </ul>
    </>
  );
};

export const TemplatePromptForm = ({
  agentId,
  templatePrompt,
  onSuccessed,
}: TemplatePromptFormProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isNewSaving, setIsNewSaving] = useState<boolean>(false);

  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const defaultEmptyForm: TemplatePromptFormValues = {
    title: "",
    prompt: "",
  };

  const initialValues = templatePrompt
    ? templatePromptFormValues(templatePrompt)
    : defaultEmptyForm;

  const handleSubmit = (
    values: TemplatePromptFormValues,
    isCreateNew?: boolean
  ) => {
    let payload: TemplatePromptParams = {
      title: values.title,
      prompt: values.prompt,
    };

    isCreateNew ? setIsNewSaving(true) : setIsSaving(true);

    const submitFunction =
      templatePrompt && !isCreateNew
        ? updateTemplatePrompt(templatePrompt.id, payload)
        : createTemplatePrompt(agentId, payload);

    submitFunction
      .then((res) => {
        if (res.data) {
          onSuccessed(res.data.data, isCreateNew);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      })
      .finally(() => {
        setIsNewSaving(false);
        setIsSaving(false);
      });
  };

  const validate = (values: TemplatePromptFormValues) => {
    let errors: FormikErrors<TemplatePromptFormValues> = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.prompt) {
      errors.prompt = "Prompt is required";
    }

    return errors;
  };

  return (
    <Formik<TemplatePromptFormValues>
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values, isCreateNew)}
      validate={validate}
    >
      {({ errors, touched, values }) => {
        return (
          <Form>
            <div className="grid grid-cols-1 gap-y-2 w-full">
              <Field
                as={FormTextField}
                name="title"
                label="Title"
                customClass="!w-full"
                errors={errors.title}
                touched={touched.title}
                required
                validate={() => {
                  touched.title = true;
                }}
              />
              <Field
                as={FormTextAreaField}
                name="prompt"
                label="Prompt"
                rows={10}
                errors={errors.prompt}
                touched={touched.prompt}
                required
                validate={() => {
                  touched.prompt = true;
                }}
                tooltip={<Tooltip content={PromptTooltip()} />}
              />
              <div className="flex flex-row gap-x-2 justify-end mt-5">
                <button
                  className="btn-primary"
                  onClick={() => setIsCreateNew(false)}
                >
                  {isSaving ? (
                    <div className="flex flex-row items-center text-white gap-x-2">
                      <div>
                        <Spinner className="w-4 h-4 text-blue" />
                      </div>
                      <div>Saving</div>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
                {templatePrompt && (
                  <button
                    className="btn-primary"
                    onClick={() => setIsCreateNew(true)}
                  >
                    {isNewSaving ? (
                      <div className="flex flex-row items-center text-white gap-x-2">
                        <div>
                          <Spinner className="w-4 h-4 text-blue" />
                        </div>
                        <div>Saving</div>
                      </div>
                    ) : (
                      "Save as New"
                    )}
                  </button>
                )}
              </div>
            </div>
            <ErrorModal
              open={showErrorModal}
              setOpen={setShowErrorModal}
              errorMessage={errorMessage}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
