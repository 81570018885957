import { ErrorMessage, ErrorMessageProps, Field, FieldProps } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { GroupAutocompleteResult, Visibility } from "../../api/types";
import GroupSelectAll from "../Select/GroupSelectAll";
import Tooltip from "../Tooltip";
import UserSelect from "../Select/UserSelect";
import { GLOBAL_GROUP_KEY } from "../Utils/constant";
import { classNames } from "../utils";

type VisibilityFieldCustomProps = {
  values: Visibility;
  hideLabel?: boolean;
};

const visibilityOptions = [
  { value: "public", label: "All users" },
  { value: "groups", label: "Specific groups/users" },
  { value: "private", label: "Only me" },
];

export default function VisibilityFieldForOpportunity({
  values,
  hideLabel = false,
}: VisibilityFieldCustomProps) {
  const { data: initialValues } = useSWR<any>(GLOBAL_GROUP_KEY);

  const [selectedGroup, setSelectedGroup] =
    useState<GroupAutocompleteResult[]>();

  useEffect(() => {
    if (initialValues) {
      setSelectedGroup(initialValues);
    }
  }, [initialValues, setSelectedGroup]);

  interface FieldErrorProps extends ErrorMessageProps {}

  const FieldError = ({ name }: FieldErrorProps) => (
    <ErrorMessage name={name}>
      {(error) => <div className="mt-1 text-red-500">{error}</div>}
    </ErrorMessage>
  );

  return (
    <>
      <fieldset className="contents">
        <legend
          className={`font-semibold text-xs mt-5 mb-1"} ${
            hideLabel ? "hidden" : ""
          }`}
        >
          <div className="flex flex-row w-full items-center mb-2 text-bold text-xs">
            Visibility
            <Tooltip
              title="What is Visibility?"
              content="Visibility allows you to have an option of deciding who can view the activity. All user activity by default is only visible to user groups or funds that you belong to."
            />
          </div>
        </legend>
        <div>
          <div className="flex space-x-1 md:space-x-6">
            {visibilityOptions.map((visibilityType) => (
              <div key={visibilityType.value} className="flex items-center">
                <Field
                  id={`visibilityField-${visibilityType.value}`}
                  name="visibility"
                  type="radio"
                  value={visibilityType.value}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor={`visibilityField-${visibilityType.value}`}
                  className="ml-1 md:ml-3 block text-2xs md:text-xs text-gray-700"
                >
                  {visibilityType.label}
                </label>
              </div>
            ))}
          </div>
          <FieldError name="visibility" />
        </div>
      </fieldset>
      <div
        className={classNames(
          "flex flex-row contents",
          values !== "groups" && "hidden"
        )}
      >
        <div className="">
          <label className="flex items-center mt-5 mb-2" htmlFor="groupsField">
            <div className="font-semibold text-xs">
              Visible to specific groups/users
            </div>
            <div className="h-4 w-4 text-red-600">*</div>
          </label>
        </div>
        <div className="flex flex-col mb-5">
          <div className="text-xs">
            <Field name="groups">
              {({
                field,
                form: { setFieldValue, touched, errors },
              }: FieldProps) => {
                touched.groups = true;
                return (
                  <div className="relative mb-2">
                    <GroupSelectAll
                      id="groupsField"
                      {...field}
                      onChange={(newValue: any) => {
                        setFieldValue(field.name, newValue);
                      }}
                      isMulti
                      placeholder="Select Group"
                      className={classNames(
                        "w-full items-center border-1 relative h-fit rounded-md",
                        touched.groups && errors.groups
                          ? "border border-1 border-red-500"
                          : "border border-1 border-blue-menu"
                      )}
                    />
                  </div>
                );
              }}
            </Field>
          </div>
          <div className="text-xs">
            <Field name="users">
              {({
                field,
                form: { setFieldValue, touched, errors },
              }: FieldProps) => {
                touched.groups = true;
                return (
                  <div className="relative">
                    <UserSelect
                      id="usersField"
                      {...field}
                      onChange={(newValue) => {
                        setFieldValue(field.name, newValue);
                      }}
                      isMulti
                      placeholder="Select User"
                      className={classNames(
                        "w-full items-center relative h-fit rounded-md",
                        touched.groups && errors.groups
                          ? "border border-1 border-red-500"
                          : "border border-1 border-blue-menu"
                      )}
                      isClearable={true}
                    />
                    <div
                      className={classNames(
                        touched.groups && errors.groups
                          ? "text-2xs text-red-600"
                          : "hidden"
                      )}
                    >
                      {errors.groups as any}
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
}
