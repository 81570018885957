import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import Snackbar from "./Snackbar";
import Spinner from "./Spinner";
import DOMPurify from "dompurify";

type ConfirmationModalProps = {
  open: boolean;
  succeed: boolean;
  succeedMessage: string;
  title: string;
  processing: boolean;
  processingText: string;
  subtitle?: string;
  submitButtonText: string;
  closeText?: string;
  showCrossButton?: boolean;
  isEditingForm?: boolean;
  onClose: () => void;
  onDiscardChanges?: () => void;
  onSubmit: () => void;
  isBlueButton?: boolean;
};
export const ConfirmationModal = ({
  open,
  succeed,
  succeedMessage,
  title,
  processing,
  processingText = "",
  subtitle,
  submitButtonText,
  closeText = "Close",
  showCrossButton = false,
  isEditingForm = false,
  onClose,
  onDiscardChanges = () => {},
  onSubmit,
  isBlueButton = false,
}: ConfirmationModalProps) => {
  return !succeed ? (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={isEditingForm ? onDiscardChanges : onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="min-h-screen px-4 text-center block">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block align-middle h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="inline-block align-middle relative rounded-lg bg-white shadow-xl transition-all transform overflow-hidden text-left px-4 pb-4 pt-5 sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  {showCrossButton && (
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={isEditingForm ? onDiscardChanges : onClose}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    {succeed ? (
                      <>
                        <div>
                          <CheckCircleIcon className="h-14 w-14 fill-green-600" />
                        </div>
                        <div className="flex flex-col text-base ml-3.5 justify-center">
                          <div className="font-medium">{succeedMessage}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <Dialog.Title className="text-sm font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="mt-2">
                          {subtitle && (
                            <p
                              className="text-xs text-gray-500 whitespace-pre-line"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(subtitle, {
                                  FORBID_TAGS: ["style"],
                                }),
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {!succeed && (
                    <button
                      className={`inline-flex w-full justify-center rounded-full ${
                        isEditingForm || isBlueButton
                          ? "bg-blue-menu hover:bg-blue-menu"
                          : "bg-red-500 hover:bg-red-500"
                      } px-3 py-2 text-2xs md:text-xs font-semibold text-white shadow-sm sm:ml-3 sm:w-auto`}
                      onClick={onSubmit}
                    >
                      {processing ? (
                        <div className="flex flex-row items-center text-white gap-x-2">
                          <div>
                            <Spinner className="w-4 h-4 text-white" />
                          </div>
                          <div>{processingText}</div>
                        </div>
                      ) : (
                        submitButtonText
                      )}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-2xs md:text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}
                  >
                    {closeText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : open ? (
    <Snackbar message={succeedMessage} onClose={onClose} />
  ) : null;
};
