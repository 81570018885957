export const SpinnerCustom = ({ className = "" }) => (
  <div className={`flex justify-center items-center h-64 ${className}`}>
    <img
      src="/loading.gif"
      className={`inline-block w-64 h-28 ${className}`}
      alt="Loading"
    />
  </div>
);
export default SpinnerCustom;
