import { AxiosError } from "axios";
import { Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  calculateVisibility,
  createAgentPersona,
  getPersonaTools,
  updateAgentPersona,
} from "../../api";
import {
  AgentPersona,
  AgentPersonaParams,
  GroupAutocompleteResult,
  PersonaTool,
} from "../../api/types";
import ErrorModal from "../ErrorModal";
import FormTextAreaField from "../Form/FormTextAreaField";
import FormTextField from "../Form/FormTextField";
import Spinner from "../Spinner";
import Tooltip from "../Tooltip";
import { parseAxiosError } from "../utils";
import { GLOBAL_GROUP_KEY } from "../Utils/constant";
import VisibilityFieldCustom from "../VisibilityFieldCustom";

type AgentFormValues = {
  name: string;
  persona: string;
  tools: string[];
  visibility: "public" | "groups" | "private" | undefined;
  groups: GroupAutocompleteResult[] | undefined;
  users: GroupAutocompleteResult[] | undefined;
};

const agentToFormValues = (agent: AgentPersona): AgentFormValues => {
  return {
    name: agent.name,
    persona: agent.persona,
    tools: agent.tools.map((tool) => tool.name),
    visibility: calculateVisibility(agent.access_policy),
    groups: agent.access_policy
      ? agent.access_policy.groups.map((group) => ({
          label: group.name,
          value: group.id,
        }))
      : [],
    users: agent.access_policy
      ? agent.access_policy.users.map((user) => ({
          label: user.name,
          value: user.id,
          email: user.email,
          image_url: user.image_url,
        }))
      : [],
  };
};

const PersonaToolTip = () => {
  return (
    <>
      <p>Best practices for creating a persona:</p>
      <ul>
        <li>
          - Specify the persona's role (e.g. You are a professional researcher
          in a top university.)
        </li>
        <li>
          - Specify the expected behaviour of how you want the agent to behave
          (e.g. You will be concise and return response in point form, along
          with citations.)
        </li>
      </ul>
    </>
  );
};

type AgentFormProps = {
  agent?: AgentPersona | undefined;
  onSuccessed: (agent: AgentPersona, isCreateNew?: boolean) => void;
};

export const AgentForm = ({ agent, onSuccessed }: AgentFormProps) => {
  const { data: selectedGroup } = useSWR<any>(GLOBAL_GROUP_KEY);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isNewSaving, setIsNewSaving] = useState<boolean>(false);
  const [tools, setTools] = useState<PersonaTool[]>([]);

  const defaultEmptyForm: AgentFormValues = {
    name: "",
    persona: "",
    tools: [],
    visibility: "groups",
    groups: selectedGroup ? Array.of(selectedGroup) : [],
    users: [],
  };

  const initialValues = agent ? agentToFormValues(agent) : defaultEmptyForm;

  useEffect(() => {
    getPersonaTools()
      .then((response) => {
        if (response.data) {
          setTools(response.data.data);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      });
  }, []);

  const handleSubmit = (values: AgentFormValues, isCreateNew?: boolean) => {
    let payload: AgentPersonaParams = {
      name: values.name,
      persona: values.persona,
      tools: values.tools,
      visibility: values.visibility,
      groups: (values.groups || []).map((option) => option.value),
      users: (values.users || []).map((option) => option.value),
    };

    isCreateNew ? setIsNewSaving(true) : setIsSaving(true);

    const submitFunction =
      agent && !isCreateNew
        ? updateAgentPersona(agent.id, payload)
        : createAgentPersona(payload);

    submitFunction
      .then((res) => {
        if (res.data) {
          onSuccessed(res.data.data, isCreateNew);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      })
      .finally(() => {
        setIsNewSaving(false);
        setIsSaving(false);
      });
  };

  const validate = (values: AgentFormValues) => {
    let errors: FormikErrors<AgentFormValues> = {};

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.persona) {
      errors.persona = "Persona is required";
    }

    if (
      values.visibility === "groups" &&
      (!values.groups || values.groups?.length === 0) &&
      (!values.users || values.users?.length === 0)
    ) {
      errors.groups = "At least one group OR user should be selected";
    }

    return errors;
  };

  return (
    <Formik<AgentFormValues>
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values, isCreateNew)}
      validate={validate}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <Form>
            <div className="grid grid-cols-1 gap-y-2 w-full">
              <Field
                as={FormTextField}
                name="name"
                label="Name"
                customClass="!w-full"
                errors={errors.name}
                touched={touched.name}
                required
                validate={() => {
                  touched.name = true;
                }}
              />
              <Field
                as={FormTextAreaField}
                name="persona"
                label="Persona"
                rows={10}
                errors={errors.persona}
                touched={touched.persona}
                required
                validate={() => {
                  touched.persona = true;
                }}
                tooltip={<Tooltip content={PersonaToolTip()} />}
              />
              <div className="flex flex-col gap-y-2 mt-3 w-full">
                <div className="flex font-semibold text-xs">
                  Tools
                  <div className="text-red-600 text-sm font-semibold">*</div>
                </div>
                <div className="grid-cols-1 grid md:grid-cols-2 gap-3">
                  {tools.map((tool: PersonaTool) => (
                    <div
                      key={tool.name}
                      className="flex flex-row gap-x-5 items-center justify-between md:justify-start"
                    >
                      <div className="flex-1 flex flex-row items-center gap-x-2">
                        <div className="w-fit text-left">{tool.display}</div>
                        <div className="w-4 h-4">
                          <Tooltip content={tool.description} />
                        </div>
                      </div>
                      <label className="flex-1 inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={values.tools.includes(tool.name)}
                          className="sr-only peer"
                          onChange={(event) => {
                            setFieldValue(
                              "tools",
                              event.target.checked
                                ? [...values.tools, tool.name]
                                : values.tools.filter(
                                    (item: string) => item !== tool.name
                                  )
                            );
                          }}
                        />
                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <VisibilityFieldCustom values={values.visibility} />
              <div className="flex flex-row gap-x-2 justify-end mt-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    setIsCreateNew(false);
                  }}
                >
                  {isSaving ? (
                    <div className="flex flex-row items-center text-white gap-x-2">
                      <div>
                        <Spinner className="w-4 h-4 text-blue" />
                      </div>
                      <div>Saving</div>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
                {agent && (
                  <button
                    className="btn-primary-container"
                    onClick={() => {
                      setIsCreateNew(true);
                    }}
                  >
                    {isNewSaving ? (
                      <div className="flex flex-row items-center text-white gap-x-2">
                        <div>
                          <Spinner className="w-4 h-4 text-blue" />
                        </div>
                        <div>Saving</div>
                      </div>
                    ) : (
                      "Save As New Agent"
                    )}
                  </button>
                )}
              </div>
            </div>
            <ErrorModal
              open={showErrorModal}
              setOpen={setShowErrorModal}
              errorMessage={errorMessage}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
