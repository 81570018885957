import { LicenseManager } from "ag-grid-enterprise";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import AuthProvider from "../components/AuthProvider";
import Breakpoints from "../components/Breakpoints";
import BrowserTitle from "../components/BrowserTitle";
import LayoutWithHeader from "../components/LayoutWithHeader";
import "../styles/globals.css";

LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY || "");

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TimeAgo.setDefaultLocale(en.locale);
    TimeAgo.addLocale(en);
  }, []);

  return (
    <>
      <Head>
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
          crossOrigin="anonymous"
        />
      </Head>
      <BrowserTitle />
      <AuthProvider>
        <LayoutWithHeader>
          <Component {...pageProps} />
          {process.env.NODE_ENV === "development" && <Breakpoints />}
        </LayoutWithHeader>
      </AuthProvider>
    </>
  );
}

export default MyApp;
