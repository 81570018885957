import { PaperClipIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ErrorMessage, ErrorMessageProps, Field, FieldProps } from "formik";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Dropzone from "react-dropzone";
import useSWR from "swr";
import {
  AutocompleteResult,
  GroupAutocompleteResult,
  NoteKind,
  Option,
  UserAutocompleteResult,
  Visibility,
} from "../../api/types";
import FileThumbnail from "../FileThumbnail";
import FormCompanySelectField from "../Form/FormCompanySelectField";
import { useOpportunity } from "../Hook/useOpportunity";
import AutocompleteMultiselect from "../Select/AutocompleteMultiselect";
import OpportunitySelect from "../Select/OpportunitySelect";
import PersonSelect from "../Select/PersonSelect";
import UserSelect from "../Select/UserSelect";
import TinyMCEEditor from "../TinyMCEEditor";
import Tooltip from "../Tooltip";
import { classNames } from "../utils";
import { GLOBAL_GROUP_KEY } from "../Utils/constant";
import VisibilityFieldCustom from "../VisibilityFieldCustom";

interface FieldErrorProps extends ErrorMessageProps {
  name: keyof CreateMeetingNoteParams;
}

export const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => (
      <div className="text-2xs text-red-500 border-red-500">{error}</div>
    )}
  </ErrorMessage>
);

export interface CreateMeetingNoteParams {
  noteKind: NoteKind;
  isMeetingNote: boolean;
  isDraft: boolean;
  title: string;
  types: string[];
  date: string;
  owner: UserAutocompleteResult | null;
  attendees: Option[];
  organizations: Option[];
  opportunities: Option[];
  content: string;
  tags: Option[];
  visibility: Visibility;
  groups: GroupAutocompleteResult[];
  users: UserAutocompleteResult[];
  attachments: File[] | undefined;
  added_attachments: AutocompleteResult[];
  audioAttachments: File[] | undefined;
  added_audioAttachment: AutocompleteResult[];
  index_note: boolean;
  hasBigFileSize: boolean;
}

interface MeetingNoteFormProps {
  values: CreateMeetingNoteParams;
}

export default function MeetingNoteForm({ values }: MeetingNoteFormProps) {
  const { types: opportunityTypes } = useOpportunity();
  const router = useRouter();
  const { pk } = router.query;
  const { data: selectedGroup } = useSWR<any>(GLOBAL_GROUP_KEY);

  const meetingTypes: string[] = [
    "Investment",
    "Portfolio Support and Partnerships",
    "LP Service and Fundraising",
    "Communications & Communities",
  ];

  useEffect(() => {
    if (!pk && selectedGroup && values.groups?.length === 0) {
      values.groups = Array.of(selectedGroup);
    }
    // eslint-disable-next-line
  }, [selectedGroup]);

  return (
    <>
      <div className="mt-2 item-centre ">
        <div className="flex flex-col items-start gap-y-1">
          <label className="flex flex-row mb-1">
            <div className="text-xs font-semibold">Type(s)</div>
            <div className="h-4 w-4 text-red-600">*</div>
          </label>
          <div className="flex items-center text-xs gap-x-4 col-span-3 flex-wrap gap-y-3">
            {meetingTypes.map((meetingType) => (
              <Field
                type="checkbox"
                value={meetingType}
                name="types"
                key={meetingType}
              >
                {({ field }: FieldProps<string[]>) => (
                  <label key={meetingType}>
                    <input type="checkbox" {...field} className="peer hidden" />
                    <div className="cursor-pointer rounded-full border border-1 border-gray-500 px-4 py-2 text-black peer-checked:bg-blue-menu font-medium peer-checked:text-white peer-checked:border-blue-500 hover:bg-gray-100">
                      {meetingType}
                    </div>
                  </label>
                )}
              </Field>
            ))}
            <FieldError name="types" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 items-start">
          <div className="mt-3">
            <label className="flex mb-1">
              <div className="text-xs font-semibold">Title</div>
              <div className="h-4 w-4 text-red-600">*</div>
            </label>
            <div className="md:w-11/12 text-xs">
              <Field name="title">
                {({ field, meta: { touched, error } }: FieldProps) => (
                  <div className="flex items-center relative">
                    <input
                      type="text"
                      placeholder="Title"
                      className={classNames(
                        "rounded-md text-xs font-sm border-gray-300 border-1 w-full max-w-full",
                        touched && error ? "border-red-500" : ""
                      )}
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <FieldError name="title" />
            </div>
          </div>
          <div className="mt-3 md:ml-10">
            <label
              className="flex text-xs font-semibold mb-1"
              htmlFor="dateField"
            >
              Date
            </label>
            <div>
              <Field name="date">
                {({ field }: FieldProps) => (
                  <input
                    id="dateField"
                    type="date"
                    className="rounded-md border-gray-300 text-xs md:w-11/12 w-full"
                    {...field}
                  />
                )}
              </Field>
              <FieldError name="date" />
            </div>
          </div>
          <div className="text-xs mt-3">
            <Field name="organizations">
              {({
                field,
                form: { setFieldValue, errors, touched },
              }: FieldProps) => (
                <Field
                  id="meeting-note-form-organizations-field"
                  as={FormCompanySelectField}
                  name="organizations"
                  label="Organization"
                  placeholder={"Select Organization"}
                  errors={errors.organizations}
                  touched={touched.organizations}
                  flexible={true}
                  withNinja={true}
                  required={false}
                  isMulti={true}
                  directCreate={false}
                  customClass={"w-11/12"}
                  onChange={(sourceValue: any) => {
                    if (sourceValue) {
                      values.organizations = sourceValue;
                    } else {
                      values.organizations = [];
                    }
                  }}
                  validate={() => {
                    touched.organizations = true;
                  }}
                />
              )}
            </Field>
          </div>
          <div className="mt-3 md:ml-10">
            <label
              className="flex text-xs font-semibold mb-1"
              htmlFor="ownerField"
            >
              Owner
            </label>
            <div className="md:w-11/12 w-full text-xs">
              <Field name="owner">
                {({
                  field: { value, name },
                  form: { setFieldValue },
                }: FieldProps<UserAutocompleteResult>) => (
                  <UserSelect
                    id="ownerField"
                    value={value}
                    onChange={(newValue) => setFieldValue(name, newValue)}
                  />
                )}
              </Field>
              <FieldError name="owner" />
            </div>
          </div>
          <div className="mt-3">
            <label
              className="flex text-xs font-semibold mb-1"
              htmlFor="attendeesField"
            >
              Attendees
            </label>
            <div className="text-xs">
              <Field name="attendees">
                {({
                  field: { name, value },
                  form: { setFieldValue },
                }: FieldProps) => (
                  <PersonSelect
                    id="attendeesField"
                    value={value}
                    onChange={(value) => setFieldValue(name, value)}
                    isMulti
                    setValue={(value) => setFieldValue(name, value)}
                    fixWidth
                  />
                )}
              </Field>
              <FieldError name="attendees" />
            </div>
          </div>

          <div className="relative mt-3 md:ml-10">
            <label
              className="flex text-xs font-semibold flex items-center mb-1"
              htmlFor="opportunitiesField"
            >
              Opportunities
              <Tooltip
                title="What are opportunities?"
                content="Opportunities are trackable pipeline items with its own individual track. Adding opportunities are essential to ensure accurate pipeline monitoring."
              />
            </label>
            <div className="text-xs">
              <Field name="opportunities">
                {({ field, form: { setFieldValue } }: FieldProps) => (
                  <OpportunitySelect
                    id="opportunitiesField"
                    {...field}
                    onChange={(newValue: any) =>
                      setFieldValue(field.name, newValue)
                    }
                    isMulti
                    setValue={(value) => setFieldValue(field.name, value)}
                    opportunityTypes={opportunityTypes}
                    className="w-11/12"
                  />
                )}
              </Field>
              <FieldError name="opportunities" />
            </div>
          </div>
          <div className="mt-1">
            <VisibilityFieldCustom values={values.visibility} />
          </div>
          <div className="grid grid-col space-y-3 mt-3 md:ml-10">
            <div className="w-full md:w-11/12">
              <label
                className="flex text-xs font-semibold flex items-center mb-1"
                htmlFor="tagsField"
              >
                Tags
                <Tooltip
                  title="What are tags?"
                  content="Tags are free-form entries for easy categorisation of your notes. Tags will be seen for all users if your privacy settings are set to all."
                />
              </label>
              <div className="text-xs">
                <Field name="tags">
                  {({
                    field: { name, value },
                    form: { setFieldValue },
                  }: FieldProps) => (
                    <AutocompleteMultiselect
                      id="tagsField"
                      autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                      selected={value}
                      onChange={(newValue) => setFieldValue(name, newValue)}
                      creatable
                    />
                  )}
                </Field>
                <FieldError name="tags" />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col gap-y-2 mt-5 md:pr-[8%]">
                <div className="flex space-x-3 mt-2 block">
                  <div className="font-semibold text-sm block">
                    Attachments (32 MB)
                  </div>
                  <PaperClipIcon className="h-4 w-4" />
                </div>
                <Field name="attachments">
                  {({
                    field: { name, value },
                    form: { setFieldValue },
                  }: FieldProps<File[]>) => (
                    <div>
                      <div>
                        <Field name="added_attachments">
                          {({
                            field: { name: fieldName, value: fieldValues },
                            form: { setFieldValue: setAttachmentFieldValues },
                          }: FieldProps<AutocompleteResult[]>) => (
                            <div className="grid grid-col-auto space-y-1">
                              {fieldValues &&
                                fieldValues.map((file) => (
                                  <FileThumbnail
                                    file={file.label}
                                    field={fieldName}
                                    value={fieldValues}
                                    setValue={setAttachmentFieldValues}
                                    key={file.value}
                                  />
                                ))}
                            </div>
                          )}
                        </Field>
                        <div className="flex flex-col gap-y-2 mb-4">
                          {value &&
                            value.map((file) => (
                              <FileThumbnail
                                file={file}
                                field={name}
                                value={value}
                                setValue={setFieldValue}
                                key={file.name}
                              />
                            ))}
                        </div>
                      </div>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          // do nothing if no files
                          if (acceptedFiles.length === 0) {
                            return;
                          }
                          // on drop we add to the existing files
                          if (value) {
                            setFieldValue(name, value.concat(acceptedFiles));
                          } else {
                            setFieldValue(name, acceptedFiles);
                          }
                        }}
                      >
                        {({
                          isDragActive,
                          isDragReject,
                          getRootProps,
                          getInputProps,
                        }) => {
                          if (isDragActive) {
                            return (
                              <div
                                className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center bg-blue-50 border-grey-500 cursor-pointer border-dashed border-sky-200"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="text-center">
                                  <div className="font-semibold text-xs text-blue-menu">
                                    Drop in this file!
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          if (isDragReject) {
                            return (
                              <div
                                className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center bg-blue-50 border-grey-500 cursor-pointer border-dashed border-sky-200"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <XCircleIcon className="h-6 w-6 fill-red-500" />
                                <div className="text-center">
                                  <div className="font-semibold text-xs text-blue-menu">
                                    This file cannot be uploaded!
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center border-grey-500 cursor-pointer border-dashed"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center">
                                <div className="text-xs text-gray-500">
                                  Drop files here or{" "}
                                  <span className="text-blue-500 underline">
                                    browse
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  )}
                </Field>
                <FieldError name="attachments" />
              </div>

              <div className="flex flex-col gap-y-2 mt-5 md:pr-[8%]">
                <div className="flex mt-2 block">
                  <div className="font-semibold text-sm block">
                    Audio Transcription Clip
                  </div>
                  <PaperClipIcon className="h-4 w-4 ml-3" />
                  <Tooltip
                    title="Audio Transcription"
                    content=" Once the transcription is completed, an email notification will be sent to you. The transcription service leverages OpenAI's Whisper LLM. Do not use this if your audio clip contains confidential or sensitive information."
                  />
                </div>
                <Field name="audioAttachments">
                  {({
                    field: { name, value },
                    form: { setFieldValue },
                  }: FieldProps<File[]>) => (
                    <div>
                      <div>
                        <Field name="added_audioAttachments">
                          {({
                            field: { name: fieldName, value: fieldValues },
                            form: { setFieldValue: setAttachmentFieldValues },
                          }: FieldProps<AutocompleteResult[]>) => (
                            <div className="grid grid-col-auto space-y-1">
                              {fieldValues &&
                                fieldValues.map((file) => (
                                  <FileThumbnail
                                    file={file.label}
                                    field={fieldName}
                                    value={fieldValues}
                                    setValue={setAttachmentFieldValues}
                                    key={file.value}
                                  />
                                ))}
                            </div>
                          )}
                        </Field>
                        <div className="flex flex-col gap-y-2 mb-4">
                          {values.audioAttachments &&
                            values.audioAttachments.length > 0 &&
                            values.audioAttachments.map((file) => (
                              <FileThumbnail
                                file={file}
                                field={name}
                                value={value}
                                setValue={setFieldValue}
                                key={file.name}
                              />
                            ))}
                        </div>
                      </div>
                      <Dropzone
                        accept={{
                          "audio/x-m4a": [".m4a"],
                          "audio/mpeg": [".mp3"],
                          "audio/webm": [".webm"],
                          "video/mp4": [".mp4"],
                          "audio/wav": [".wav"],
                          "video/mpeg": [".mpeg"],
                          "audio/mpga": [".mpga"],
                        }}
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          // do nothing if no files
                          if (acceptedFiles.length === 0) {
                            return;
                          }

                          setFieldValue(name, acceptedFiles);
                        }}
                      >
                        {({
                          isDragActive,
                          isDragReject,
                          getRootProps,
                          getInputProps,
                        }) => {
                          if (isDragActive) {
                            return (
                              <div
                                className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center bg-blue-50 border-grey-500 cursor-pointer border-dashed border-sky-200"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="text-center">
                                  <div className="font-semibold text-xs text-blue-menu">
                                    Drop in this audio file!
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          if (isDragReject) {
                            return (
                              <div
                                className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center bg-blue-50 border-grey-500 cursor-pointer border-dashed border-sky-200"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <XCircleIcon className="h-6 w-6 fill-red-500" />
                                <div className="text-center">
                                  <div className="font-semibold text-xs text-blue-menu">
                                    This audio file cannot be uploaded!
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="rounded-md p-4 border-2 flex flex-col justify-evenly items-center border-grey-500 cursor-pointer border-dashed"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center">
                                <div className="text-xs text-gray-500">
                                  Drop audio files here or{" "}
                                  <span className="text-blue-500 underline">
                                    browse
                                  </span>
                                  <div>
                                    (Accepts m4a, mp3, webm, wav, mpeg, mpga.)
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  )}
                </Field>
                <FieldError name="attachments" />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-x-3 my-3 h-full items-center">
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <label className="text-xs font-bold">Index Note</label>
                <div className="relative mr-2">
                  <Tooltip
                    tooltipClass="w-96"
                    title={""}
                    content={
                      "Indexing a note will cause the content of the note to be ingested by LLMs and searchable via AskVAL. Notes with sensitive and confidential information should not be indexed."
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <Field name="index_note">
                {({ field, form: { setFieldValue } }: FieldProps) => (
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      defaultChecked={values.index_note}
                      className="sr-only peer"
                      onChange={(event) => {
                        setFieldValue(field.name, event.currentTarget.checked);
                      }}
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                )}
              </Field>
            </div>
          </div>
        </div>
      </div>

      <div className="z-0">
        <label className="flex text-xs font-semibold mb-1">Content</label>
        <Field name="content">
          {({
            field: { name, value },
            form: { setFieldValue, setErrors, errors },
          }: FieldProps) => (
            <TinyMCEEditor
              value={value}
              handleEditorChange={(stringifiedHtmlValue, fileSize) => {
                setFieldValue("hasBigFileSize", fileSize && fileSize > 5);
                setFieldValue("content", stringifiedHtmlValue);
              }}
            />
          )}
        </Field>
        <FieldError name="content" />
      </div>
    </>
  );
}
