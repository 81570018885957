import * as api from "../../api";
import { CountryStub } from "../../api/types";
import { Country } from "../CountryFlag";
import Select from "./Select";

type CountrySelectProps = {
  autocompleteCountry?: (q: string) => Promise<CountryStub[]>;
  id?: string;
  value?: any;
  onChange: (value: any) => void;
  isMulti?: boolean;
  isClearable?: boolean;
  onBlur?: () => void;
};

const formatCountryOption = (result: any) => {
  if (!result) return null;
  return (
    <div className="text-xs">
      <Country alpha2={result.value} countryName={result.label} />
    </div>
  );
};

export default function CountrySelect({
  autocompleteCountry,
  onBlur,
  ...props
}: CountrySelectProps) {
  const loadOptions = api.autocompleteCountry;
  return (
    <Select
      formatOptionLabel={formatCountryOption}
      onBlur={onBlur && onBlur}
      {...props}
      loadOptions={loadOptions}
    />
  );
}
