import { useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import * as api from "../../api";
import { UserAutocompleteResult } from "../../api/types";
import Avatar from "../Avatar";
import Select from "./Select";

function formatPersonOption(result: UserAutocompleteResult) {
  if (!result) return null;
  return (
    <div className="flex items-center gap-x-3">
      <div className="block">
        <Avatar name={result.label} size={30} url={result.image_url} />
      </div>
      <div className="text-justify">
        <h1 className="font-bold text-2xs">{result.label}</h1>
        <p className="text-2xs break-all">{result.email}</p>
      </div>
    </div>
  );
}

type UserSelectProps = {
  autocompleteUsers?: (q: string) => Promise<UserAutocompleteResult[]>;
  value?: any;
  onChange?: (value: any) => void;
  isMulti?: boolean;
  placeholder?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
};

export default function UserSelect<Option, IsMulti extends boolean>({
  autocompleteUsers,
  ...props
}: UserSelectProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data: options } = useSWR<UserAutocompleteResult[]>(
    `/api/users/autocomplete/users?q=${debouncedQuery}&limit=100`,
    api.getter,
    { revalidateOnFocus: false }
  );

  return (
    <Select
      options={options}
      formatOptionLabel={formatPersonOption}
      value={props.value}
      onChange={props.onChange}
      onInputChange={(value) => {
        setQuery(value);
      }}
      {...props}
    />
  );
}
