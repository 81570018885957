import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowSmallLeftIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, PropsWithChildren, useState } from "react";
import useSWR from "swr";
import CreateDropDownMenu from "./CreateDropDownMenu";
import ProfileMenu from "./ProfileMenu";
import QuickSearch from "./QuickSearch";
import MobileSidebar from "./SidebarMobileView";
import { setNavigation } from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";
import { classNames } from "./utils";

type DropDownMenuProps = {
  navOpen: boolean;
};

type GlobalGroupProps = {
  label: string;
  value: string;
};

const DropDownMenu = ({ navOpen }: DropDownMenuProps) => {
  return <ProfileMenu navOpen={navOpen} />;
};

const NAV_BAR_KEY = "navBarStatus";

const getNavBarStatus: () => boolean = () => {
  const status = window.localStorage.getItem(NAV_BAR_KEY) === "true";
  return status;
};

const setNavBarStatus = (status: boolean) => {
  window.localStorage.setItem(NAV_BAR_KEY, status.toString());
  window.dispatchEvent(new Event("storage"));
};

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(getNavBarStatus());
  const [openQuickSearch, setOpenQuickSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  const { data: selectedGroup } = useSWR<GlobalGroupProps>(GLOBAL_GROUP_KEY);

  var updatedNavigation = setNavigation("default");

  if (selectedGroup) {
    updatedNavigation = setNavigation(selectedGroup.label);
  }

  const current = updatedNavigation.find(
    (item) =>
      item?.href === router.pathname || item.prefixes?.includes(router.pathname)
  );

  const openCloseNav = (isOpen: boolean) => {
    setNavOpen(isOpen);
    setNavBarStatus(isOpen);
  };

  const currentUrl = router.asPath.slice(1).split("?")[0];

  function cleanURL(currentURL: string): { formatted: string } {
    const parts = currentURL.split("/");

    const capitalizeAndClean = (str: string) => {
      return str
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    if (parts.length === 1) {
      return { formatted: capitalizeAndClean(parts[0]) };
    } else if (parts.length === 2) {
      if (!isNaN(Number(parts[1]))) {
        return { formatted: `${capitalizeAndClean(parts[0])} (${parts[1]})` };
      } else {
        return {
          formatted: `${capitalizeAndClean(parts[0])} ${capitalizeAndClean(
            parts[1]
          )}`,
        };
      }
    } else {
      return { formatted: "Unknown URL format" };
    }
  }

  const menuText = (navOpen: boolean, text: string) => {
    // Sidebar collapsed
    if (!navOpen) {
      switch (text) {
        case "People & Organizations":
          return "Connections";
        case "Opportunities":
          return "Opps";
        case "Vertex Platform":
          return "VPCs";
        default:
          return text;
      }
    } else {
      return text;
    }
  };

  return (
    <>
      <div className="h-full flex">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex w-7 h-7 flex-col items-center justify-center rounded-full bg-white absolute -right-[13px] top-[30px] drop-shadow-lg">
                    <ChevronLeftIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => setSidebarOpen(false)}
                      aria-hidden="true"
                    />
                  </div>
                </Transition.Child>
                <MobileSidebar closeSidebar={() => setSidebarOpen(false)} />
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition>

        <div className="flex md:flex-row flex-col flex-1 h-full fixed overflow-y-auto overflow-x-hidden left-0 right-0 inset-y-0">
          <div className="sticky md:hidden top-0 z-top bg-gray-100">
            <div className="pl-4 h-14 flex bg-blue-menu items-center justify-between">
              <div
                className={`flex flex-row items-center space-x-2 ${
                  openQuickSearch ? "invisible" : "visible"
                }`}
              >
                <button
                  type="button"
                  className="h-8 w-8 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
                <button type="button" onClick={() => router.back()}>
                  <ArrowSmallLeftIcon className="w-6 h-6 text-white" />
                </button>
                <div
                  className={`flex flex-col text-sm text-white ${
                    openQuickSearch ? "invisible" : "visible"
                  }`}
                >
                  {/* {title} */}
                  {cleanURL(currentUrl).formatted}
                </div>
              </div>
              <div
                className={`flex flex-row space-x-2 pr-4 items-center ${
                  openQuickSearch ? "absolute right-0 z-[101]" : ""
                }`}
              >
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenCreate(false);
                      setOpenQuickSearch(!openQuickSearch);
                    }}
                  >
                    {!openQuickSearch ? (
                      <MagnifyingGlassIcon className="w-5 h-5 text-white" />
                    ) : (
                      <XMarkIcon className="w-5 h-5 text-white" />
                    )}
                  </button>
                </div>
                <div>
                  <button
                    id="create-dropdown-menu-mobile-plus-button"
                    type="button"
                    onClick={() => {
                      setOpenQuickSearch(false);
                      setOpenCreate(!openCreate);
                    }}
                  >
                    <PlusIcon className="w-5 h-5 text-white" />
                  </button>
                </div>
              </div>
              <div
                className={`lg:invisible absolute w-10/12 z-top top-0 right-0 left-0 w-full h-full duration-300 pl-6 pr-6 flex items-center ${
                  openQuickSearch === true
                    ? "visible translate-x-0"
                    : "invisible translate-x-full"
                }`}
              >
                {openQuickSearch && <QuickSearch isMobile={true} />}
              </div>
              <CreateDropDownMenu
                showInHeader={true}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
              />
            </div>
          </div>
          <div className="flex hidden md:block shadow-lg bg-blue-menu fixed left-0">
            <div
              className={`h-screen ${
                navOpen ? "w-[270px]" : "w-16"
              } duration-300 relative`}
            >
              <div className="flex flex-col justify-between h-full mt-2.5 no-scrollbar overflow-x-auto">
                <div className={`flex flex-col items-left`}>
                  <div className={`flex flex-row ${!navOpen ? "mt-10" : ""}`}>
                    {navOpen && (
                      <div className="flex flex-row">
                        <img
                          src="/labs-icon.png"
                          alt="Samurai logo"
                          className="pt-2 w-[102px] h-[60px] ml-[30px] mb-5 mt-2 cursor-pointer"
                          onClick={() =>
                            router.push(
                              envString === "vvseai" || envString === "vvhc"
                                ? "/dealflow"
                                : "/"
                            )
                          }
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex w-7 h-7 flex-col items-center justify-center rounded-full bg-white absolute -right-[13px] top-[19px] drop-shadow-lg">
                        {navOpen ? (
                          <ChevronLeftIcon
                            className="w-5 h-5 cursor-pointer"
                            onClick={() => openCloseNav(false)}
                          />
                        ) : (
                          <ChevronRightIcon
                            className="w-5 h-5 cursor-pointer"
                            onClick={() => openCloseNav(true)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {updatedNavigation.map((item: any) =>
                    item ? (
                      <div
                        key={item.key}
                        className={`my-1 duration-300 ${
                          navOpen
                            ? item.key === current?.key
                              ? "bg-white rounded-full mx-3 h-14"
                              : "bg-blue-menu hover:bg-white/50 hover:rounded-full mx-3 h-14"
                            : ""
                        } `}
                      >
                        <Link
                          href={item.href}
                          key={item.key}
                          title={item.name}
                          onClick={() => {
                            if (
                              item.key === current?.key &&
                              item.key === "chatbot"
                            ) {
                              router.reload();
                            } else {
                              router.push(item.href, undefined, {
                                shallow: true,
                              });
                            }
                          }}
                          className={classNames(
                            item.key === current?.key
                              ? "border-blue-menu text-blue-menu font-medium"
                              : `border-transparent text-gray-300 font-light ${
                                  navOpen && "hover:text-black"
                                }`,
                            `flex ${
                              navOpen ? "flex-row pl-4 pr-6" : "flex-col"
                            } items-center h-full w-full text-sm tracking-wide font-light`
                          )}
                        >
                          <div
                            className={`flex flex-col duration-300 w-10 h-10 ${
                              navOpen && "!w-6 !h-6 mr-3"
                            } items-center justify-center ${
                              !navOpen
                                ? item.key === current?.key
                                  ? "rounded-full bg-white mb-1"
                                  : "hover:bg-white/50 hover:rounded-full hover:text-black"
                                : ""
                            }`}
                          >
                            <item.icon
                              className={`flex-shrink-0 ${
                                navOpen ? "h-6 w-6" : "w-5 h-5"
                              }`}
                              aria-hidden="true"
                            />
                          </div>
                          <span
                            className={`duration-300 origin-left ${
                              !navOpen
                                ? `text-3xs ${
                                    item.key === current?.key
                                      ? "text-white font-normal"
                                      : "text-gray-300 font-light"
                                  }`
                                : ""
                            }`}
                          >
                            {menuText(navOpen, item.name)}
                          </span>
                        </Link>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </div>
          <main
            className={`h-full w-full duration-300 ml-0 overflow-y-auto md:overflow-x-hidden ${
              navOpen ? "md:ml-[270px]" : "md:ml-16"
            }`}
            id="sidebar-wide-view"
          >
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
