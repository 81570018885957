import { PlusIcon } from "@heroicons/react/24/solid";
import { FormikErrors, FormikTouched, useField } from "formik";
import React, { useState } from "react";
import { OrganizationAutocompleteResult } from "../../api/types";
import NewModal from "../NewModal";
import OrganizationForm from "../discover/Organizations/OrganizationForm";
import OrganizationSelect from "../Select/OrganizationSelect";
import { classNames } from "../utils";

type FormCompanySelectFieldProps = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  required: boolean;
  errors:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  flexible: boolean;
  onChange?: (value: any) => void;
  countryChange?: (value: any) => void;
  descriptionChange?: (value: any) => void;
  directCreate?: boolean;
  setIsDescriptionEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNameEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  withNinja?: boolean;
  canAddOrganization?: boolean;
  customClass?: string;
  isMulti?: boolean;
  onBlur?: () => void;
  setId?: React.Dispatch<
    React.SetStateAction<string | number | null | undefined>
  >;
};

const FormCompanySelectField = ({
  id,
  name,
  required,
  label,
  placeholder,
  errors,
  touched,
  flexible,
  onChange,
  countryChange,
  descriptionChange,
  directCreate = false,
  setIsDescriptionEdited,
  setIsNameEdited,
  withNinja,
  canAddOrganization = true,
  customClass,
  isMulti,
  onBlur,
  setId,
}: FormCompanySelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  let { value, checked } = input;
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="flex">
        <label className="font-semibold text-xs" htmlFor={name}>
          {label}
        </label>
        {required ? (
          <div className="text-red-600 text-sm font-semibold">*</div>
        ) : (
          ""
        )}
      </div>
      <div className="flex items-center gap-2">
        <div
          className={`w-full rounded-md ${customClass} ${
            touched
              ? errors
                ? "border border-1 border-red-500"
                : "border border-1 border-blue-menu"
              : "border-gray-300"
          }`}
        >
          <OrganizationSelect
            id={id}
            withNinja={withNinja}
            value={value?.value || value?.length ? value : []}
            placeholder={placeholder}
            isMulti={isMulti}
            onChange={(newValue: any) => {
              setValue(newValue);
              if (countryChange) {
                countryChange(newValue?.country);
              }
              if (descriptionChange) {
                descriptionChange(newValue?.description);
              }
              if (onChange?.length) {
                onChange(newValue);
              }
              if (setId && newValue?.value) {
                setId(newValue?.value);
              }
            }}
            flexible={flexible}
            directCreate={directCreate}
            onBlur={onBlur}
          />
        </div>
        {canAddOrganization && (
          <div
            className="bg-blue-menu flex justify-center items-center w-5 h-5 rounded-full cursor-pointer"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <PlusIcon className="h-5 w-5 fill-white" />
          </div>
        )}
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden"
        )}
      >
        {errors?.toString()}
      </div>
      <NewModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <OrganizationForm
          isOpen={isOpen}
          title="Add New Organization"
          setIsOpen={setIsOpen}
          newOrganization={(organization) => {
            const newOrganization: OrganizationAutocompleteResult = {
              label: organization.name,
              value: organization.id,
              domain: organization.domain,
              image_url: organization.logo_url,
              description: organization.description,
            };

            if (isMulti) {
              setValue([...(value || []), newOrganization]);
            } else {
              setValue(newOrganization);
            }

            setIsDescriptionEdited && setIsDescriptionEdited(false);
            setIsNameEdited && setIsNameEdited(false);
          }}
        />
      </NewModal>
    </div>
  );
};

export default FormCompanySelectField;
