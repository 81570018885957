declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useState } from "react";
import { downloadFile } from "../../api";
import { AutocompleteResult } from "../../api/types";
import ErrorModal from "../ErrorModal";

type FileProps = {
  doc: AutocompleteResult;
  onError?: (hasError: boolean) => void;
};

var BASE64_MARKER = ";base64,";

const convertDataURIToBinary = (dataURI: any) => {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const getBrowserName = () => {
  let browserName: string = "";

  let userAgent = window.navigator.userAgent;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }
  return browserName;
};

export const File = ({ doc, onError }: FileProps) => {
  const [hovering, setHovering] = useState(false);
  const [errorMessages, setErrorsMessages] = useState<string>("");
  const [errorModal, setErrorModal] = useState(false);

  const handleLinkClick = async () => {
    viewAndDownloadAttachment({ fileId: doc.value, fileName: doc.label }).catch(
      (error) => {
        setErrorModal(true);
        setErrorsMessages(
          "Try to log out and log back in again. If the issue persists, please contact labs-support@vertexholdings.com"
        );
      }
    );
  };

  return (
    <>
      <Link href={`#`} passHref legacyBehavior>
        <div
          className="flex flex-row items-center p-3 h-10 cursor-pointer break-all"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={() => handleLinkClick()}
        >
          {hovering ? (
            <ArrowDownTrayIcon className="w-4 min-w-[1rem] mr-2 text-blue-500" />
          ) : (
            <DocumentTextIcon className="w-4 h-4 min-w-[1rem] mr-2 text-blue-500" />
          )}
          <a className="text-xs font-medium text-blue-500">{doc.label}</a>
        </div>
      </Link>
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessages}
      />
    </>
  );
};

export const viewAndDownloadAttachment = ({
  fileId,
  fileName,
}: {
  fileId: number;
  fileName: string;
}) => {
  const browserName = getBrowserName();
  const fileTypes = [
    {
      extension: "pdf",
      type: "application/pdf",
    },
  ];
  return downloadFile(fileId).then((response: any) => {
    if (response.data) {
      const extensionFile = fileName.split(".").pop()?.toLowerCase() ?? "";
      const fileType = fileTypes.filter(
        (type) => type.extension === extensionFile
      )[0];
      if (fileType) {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          var base64String = reader.result;
          var pdfAsArray = convertDataURIToBinary(base64String);
          let blob = new Blob([pdfAsArray], { type: fileType.type });
          const href = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = `/pdfPreview.html?url=${href}&fileId=${fileId}&fileName=${fileName}`;

          link.addEventListener("click", function (event) {
            event.preventDefault();

            if (browserName === "safari") {
              var newTab = window.open();
              if (newTab) {
                newTab.opener = null;
                newTab.location = link.href;
              } else {
                window.location.href = link.href; // Fallback in case window.open() fails
              }
            } else {
              window.open(link.href, "_blank");
            }
          });

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        };
      } else if (extensionFile === "png" || extensionFile === "jpg") {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          var base64String = reader.result;
          var pdfAsArray = convertDataURIToBinary(base64String);
          let blob = new Blob([pdfAsArray], {
            type: `image/${extensionFile}`,
          });
          const href = URL.createObjectURL(blob);

          window.open(
            `/pdfPreview.html?url=${href}&fileId=${fileId}&fileName=${fileName}`
          );
        };
      } else {
        downloadAttachment({
          fileId: fileId,
          fileName: fileName,
        });
      }
    }
  });
};

export const downloadAttachment = async ({
  fileId,
  fileName,
}: {
  fileId: number;
  fileName: string;
}) => {
  downloadFile(fileId)
    .then((response: any) => {
      if (response.data) {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    })
    .catch((error) => {
      const a = error;
    });
};
