import { useEffect, useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import * as api from "../../api";
import { AutocompleteResult, Option } from "../../api/types";
import Select from "./Select";

type VMFSelectProps = {
  isMulti: boolean;
  id: string;
  value: Option;
  fundType?: "parent" | "child";
  onChange: (selected: any) => void;
};

export default function VMFSelect({ ...props }: VMFSelectProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const [vmfData, setVmfData] = useState<Option[]>([]);
  const { data } = useSWR<AutocompleteResult[]>(
    props.fundType
      ? `/api/people_map/autocomplete/funds?limit=200&q=${debouncedQuery}&fund_type=${props.fundType}`
      : `/api/people_map/autocomplete/funds?limit=200&q=${debouncedQuery}`,
    api.getter
  );

  useEffect(() => {
    if (data) {
      setVmfData(
        data.map((item: any) => ({
          value: item.value,
          label: item.organization
            ? `${item.label} . ${item.organization.name}`
            : `${item.label}`,
        }))
      );
    }
  }, [data]);

  return (
    <Select setInputValue={setQuery} {...props} options={vmfData} isClearable />
  );
}
