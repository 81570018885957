import { ColDef, ColumnState } from "ag-grid-community";
import { NextRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";
import { SortingRule, TableState } from "react-table";
import { KeyedMutator } from "swr";

export type AuthResponse = {
  id: number;
  email: string;
  name: string;
  first_name: string;
  image_url: string;
  token: string;
  feature_group: string;
};

export type OrganizationCount = {
  companyCount: number;
  investorCount: number;
  total: number;
};

export type MapOrganization = {
  index: string;
  provided_company_id: number;
  provided_company_name: string;
  country: CountryStub | null;
  company_name: string;
  logo_url: string;
  description: string;
  technologies: string[];
  industries: string[];
  connections: EmployeeConnection[];
};

export type DashboardResponse = {
  total_people_connections: number;
  total_organization_connections: number;
  recent_people_connections: Array<{
    id: number;
    first_name: string;
    last_name: string;
    organization: string;
    image_url: string;
  }>;
  recent_organization_connections: Array<{
    id: number;
    name: string;
    logo_url: string;
  }>;
  organization_count: OrganizationCount;
  recent_vpcs: Array<{
    id: number;
    name: string;
    logo_url: string;
  }>;
  latest_news: News[];
};

export type DashboardStatistics = {
  total_people_connections: number;
  total_organization_connections: number;
  organization_count: OrganizationCount;
};

export type DashboardOrganization = {
  id: number;
  name: string;
  logo_url: string;
  description: string;
  investment_details: {
    investor_fund: FundDetail;
    id: number;
    investment_date: string;
  };
};

export type DashboardOpportunities = {
  image_url: string | undefined;
  label: string;
  value: number;
  opportunity_type: string;
  funnel_stage: string;
  owner: UserStub;
  organizations: OrganizationAutocompleteResult[];
};

export type News = {
  comment: string;
  created_at: string;
  id: number;
  organizations: Array<{
    domain: string;
    id: number;
    image_url: string;
    name: string;
    uuid: string;
  }>;
  publish_date: string;
  source: string;
  url: string;
  title: string;
};

export type ActivityUser = {
  first_name: string;
  last_name: string;
  email: string;
  image_url: string;
};

export type ActivityPerson = { id: number; name: string; image_url: string };

export type ActivityOrganization = {
  id: number;
  name: string;
  logo_url: string;
};

export type ActivityMeetingNote = {
  id: number;
  title: string;
};

export type UserJoinedActivity = {
  type: "user_joined";
  timestamp: string;
  user: ActivityUser;
};

export type EmployeeAddedActivity = {
  type: "employee_added";
  timestamp: string;
  person: ActivityPerson;
  organization: ActivityOrganization;
};

export type OrganizationAddedActivity = {
  type: "organization_added";
  timestamp: string;
  organization: ActivityOrganization;
};

export type ConnectionMadeActivity = {
  type: "connection_made";
  timestamp: string;
  person: ActivityPerson;
  organization: ActivityOrganization;
  counter_person: ActivityPerson;
  counter_organization: ActivityOrganization;
};

export type MeetingNoteCreatedActivity = {
  type: "meetingnote_created";
  timestamp: string;
  person: ActivityPerson;
  organizations: ActivityOrganization[];
  meeting_note: ActivityMeetingNote;
};

export type Activity =
  | UserJoinedActivity
  | EmployeeAddedActivity
  | ConnectionMadeActivity
  | OrganizationAddedActivity
  | MeetingNoteCreatedActivity;

export type UserDetail = {
  id: number;
  full_name: string;
};

export type UserStub = {
  id: number;
  name: string;
  email: string;
  image_url: string;
  person: number;
};

export interface Dictionary<T> {
  [Key: string]: T;
}

export type LastComment = {
  access_policy: AccessPolicy | null;
  created_at: string;
  id: number;
  text: string;
  user: UserStub;
};

export type OpportunityDetail = {
  id: number;
  name: string;
  description: string;
  owner: UserStub;
  owner_groups: Group[];
  opportunity_type: OpportunityType;
  source_organization: OrganizationStub;
  target_organization: OrganizationStub | null;
  funnel_stage: FunnelStage;
  next_uncompleted_task: WorkTaskStub;
  investors: InvestorOrganization[];
  opportunity_organizations: OpportunityOrganizationsItem[];
  funnel_stage_history: FunnelStageHistoryItem[];
  created_at: string;
  updated_at: string;
  rejected_at: string | null;
  rejected_reason: string | null;
  to_revisit_at: string | null;
  to_revisit_reason: string | null;
  access_policy: AccessPolicy | null;
  additional_fields: Dictionary<string> | null;
  last_activity: string;
  tags: Tag[];
  latest_comment: LastComment;
  partner: UserStub;
  team_members: UserStub[];
  attachments: AttachmentDetail[];
  originator: UserStub;
  contacts: UserStub[];
  in_team: boolean;
  status: "active" | "closed" | "revisit";
  interaction_date: string;
};

export type FileAttachmentDetail = {
  id: number;
  filename: string;
  created_at: string;
  owner: {
    id: number;
    name: string;
    email: string;
    image_url: string;
    person: number;
  };
  reference: GenericReference;
};

export type WorkTaskStub = {
  id: number;
  title: string;
  due_date: string;
};

export type WorkTaskDetail = {
  id: number;
  title: string;
  source: OpportunityStub;
  created_by: UserStub;
  assigned_to: UserStub;
  created_at: string;
  updated_at: string;
  due_date: string;
  completed_at: string | null;
  status: string | null;
  access_policy: AccessPolicy | null;
  opportunity_type: string;
};

export type OpportunityTableDetail = {
  id: number;
  name: string;
  description: string;
  owner: UserStub;
  opportunity_type: OpportunityType;
  source_organization: OrganizationStub;
  target_organization: OrganizationStub | null;
  funnel_stage: FunnelStage;
  created_at: string;
  updated_at: string;
  rejected_at: string | null;
  rejected_reason: string | null;
  to_revisit_at: string | null;
  to_revisit_reason: string | null;
  additional_fields: Dictionary<string> | null;
  last_activity: string;
  tags: Tag[];
  latest_comment: LastComment;
  partner: UserStub;
  team_members: UserStub[];
  originator: UserStub;
  in_team: boolean;
  updated_by: UserStub | null;
  contacts: UserStub[];
  status: "active" | "closed" | "revisit";
  next_uncompleted_task: WorkTaskStub | null;
};

export type GTMProjectDetailResponse = {
  success: boolean;
  data: GTMProjectDetail;
};

export type OpportunityWithOrganizationStub = {
  id: number;
  name: string;
  owner: UserStub;
  source_organization: OrganizationStub;
  target_organization: OrganizationStub | null;
  funnel_stage: FunnelStage;
  updated_at: string;
  last_activity: string;
  latest_comment: LastComment;
  status: "active" | "closed" | "revisit";
};

export type GTMProjectDetail = {
  id: number;
  name: string;
  description: string;
  feedback: string;
  owner: UserStub;
  owner_groups: Group[];
  team_members: UserStub[];
  created_at: string;
  updated_at: string;
  access_policy: AccessPolicy | null;
  organization: OrganizationStub;
  organization_contacts: BasicPersonDetail[];
  leads: OrganizationDetail[];
  opportunities: OpportunityWithOrganizationStub[];
  news: GTMNewsDetail[];
  events: [];
  closed_on: string | null;
  closed_reason: string;
  status: string;
};

export type FinanceStageDetail = {
  id: number;
  name: string;
};

export type FundStub = {
  id: number;
  name: string;
};

export type FundInvestmentDetail = {
  id: number;
  investor_fund: FundStub;
  investee_fund: FundStub;
};

export type FundDetail = {
  id: number;
  name: string;
  organization: OrganizationStub;
  lp_funds: FundInvestmentDetail[];
};

export type VertexPlatform = {
  id: number;
  organization: OrganizationDetail;
  financing_stage: FinanceStageDetail;
  holding_status: string;
  investment_date: string;
  funds: FundStub[];
  investors: InvestorOrganization[];
  investment_details: OrganizationInvestmentDetail[];
  sharepoint_url: string;
  is_public: boolean;
  opportunity_count: number;
  funnel_metrics: FunnelMetrics[];
  description: string;
  investment_partners: InvestmentPartners[];
};

export type OrganizationFormValues = {
  tags: Option[];
  bd_description: string;
  org_name: any;
  description: string;
  org_website: string;
  country: CountryStub | null;
  linkedin: string;
  industry: Option[];
  image: File | undefined;
  technologies: Option[];
  ticker: string;
  sharepoint_url: string;
  is_public: boolean;
  website_unknown?: boolean;
  is_vpc: boolean;
  additional_investors?: AdditionalInvestor[];
};

export type VPCDetail = {
  id: number;
  organization: OrganizationDetail;
  financing_stage: FinanceStageDetail;
  holding_status: string;
  investment_date: string;
  sharepoint_url: string;
  is_public: boolean;
  funds: FundStub[];
  investors: InvestorOrganization[];
  investment_details: OrganizationInvestmentDetail[];
  opportunity_count: number;
  funnel_metrics: FunnelMetrics[];
  technologies_vpc: string[];
  funds_vpc: string[];
  investors_vpc: string[];
  country: string;
  selected?: boolean;
};

export type GTMRecommendationDetailResponse = {
  success: boolean;
  data: GTMRecommendationDetail[];
};

export type GTMRecommendationDetail = {
  id: number;
  domain: string;
  summary: string;
  country: string;
  name: string;
  uuid: string;
  logo_url: string;
  count: number;
  reason: string[];
  connected: boolean;
  selected?: boolean;
};

export type GTMRecommendation = {
  id: string | number;
  name: string;
  country: string;
  summary: string;
  reason: string;
  connected: boolean;
  recommendation: GTMRecommendationDetail;
};

export type GTMOrganization = {
  id: string | number;
  company: string;
  description: string;
  country: CountryStub;
  industries: AutocompleteResult[];
  selected?: boolean;
  organization: OrganizationDetail;
};
export type GTMNewsDetail = {
  id: number;
  title: string;
  url: string;
  source: string;
  publish_date: string;
  comment: string;
};

export type GTMEventsDetail = {
  id: number;
  title: string;
  url: string;
  country_id: string;
  event_date: string;
  comment: string;
  country: {
    value: Option;
    alpha2: string;
    alpha3: string;
    id: number;
    name: string;
    label: string;
  };
};

export interface CreateGTMNewsParams {
  title: string;
  url: string;
  source: string;
  publish_date: string;
  comment: string;
}

export interface CreateGTMEventsParams {
  title: string;
  url: string;
  country_id: string;
  event_date: string;
  comment: string;
}

export type OpportunityOrganizationsItem = {
  id: number;
  organization: number;
  organization_name: string;
  organization_domain: string;
  organization_logo_url: string;
  opportunity: number;
  opportunity_name: string;
  relationship: string;
};

export type FunnelStageHistoryItem = {
  id: number;
  opportunity: number;
  opportunity_name: string;
  funnel_stage: number;
  funnel_stage_name: string;
  updated_by: number;
  updated_by_name: string;
  updated_at: Date;
  funnel_stage_date: string;
};

export type OrganizationDetail = {
  id: number;
  uuid: string;
  name: string;
  industries: AutocompleteResult[];
  headquarters: CountryStub;
  website: string;
  updated_at: Date;
  specialities: AutocompleteResult[];
  logo_url: string;
  description: string;
  domain: string;
  linkedin: string;
  image_url: string;
  technologies: TechnologiesDetail[];
  investors: InvestorOrganization[];
  ticker: string;
  selected?: boolean;
  financing_stage: AutocompleteResult[];
  sharepoint_url: string;
  funds: FundStub[];
  investment_date: string;
  holding_status: string;
  is_public: boolean;
  vertex_portfolio_company: VertexPortfolioCompany;
  tags: Tag[];
  vertex_relation?: string;
  latest_comment?: LastComment;
  image_file?: File | undefined;
  following?: {
    follow?: boolean;
    follow_groups_origin?: any;
  };
  last_crawl: string;
};

export type HistoryAI = {
  id: number;
  access_policy: AccessPolicy | null;
  created_at: string;
  created_by: UserStub;
  prompt: string;
  response: string;
};

export type WatchlistDetail = {
  organization: {
    id: number;
    name: string;
    headquarters: AutocompleteResult;
    logo_url: string;
    description: string;
    technologies: TechnologiesDetail[];
  };
  created_at: string;
};

export type VertexPortfolioCompany = {
  financing_stage: FinanceStageDetail;
  holding_status: string;
  id: number;
  investment_date: string;
  sharepoint_url: string;
  is_public: boolean;
  description: string;
  investment_details: OrganizationInvestmentDetail[];
};

export type FunnelMetrics = {
  name: string;
  order: number;
  count: number;
};

export type CollaborationThread = {
  id: number;
  owner: UserStub;
  title: string;
  content: string;
  content_html: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  resolved_at: string;
  comment_count: number;
};

export interface TemplateParams {
  name: string;
  group: number;
  allowed_source_type: string[];
  published: boolean;
  frequency: string | number | null;
  block_order: number[];
}

export interface ResponseParams {
  file?: File | undefined;
  params: {
    checklist_submission_id?: number;
    block_id?: number;
    value: string;
    additional_comments: string;
  };
}

export interface TemplateBlockParams {
  checklist_template_id?: number;
  question_text: string;
  question_type: string | number;
  multiple_choice_options: string[];
}

export interface TemplateSubmissionParams {
  checklist_template_id?: number;
  source_type?: string;
  time_period?: string;
  object_id?: number;
}

export interface TemplateSubmission {
  id: number;
  checklist_template: FormTemplateDetail;
  time_period: string;
  object_id: number;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
}

export type GroupDetail = {
  id: number;
  name: string;
};

export interface ResponseDetail {
  id: number;
  checklist_submission: SubmissionStub;
  block: BlockStub;
  value: string;
  additional_comments: string;
  file: Option;
  created_at: string;
  updated_by: UserStub;
  updated_at: string;
}

export interface ResponseStub {
  id: number;
  checklist_submission_id: number;
  block_id: number;
  value: string;
  additional_comments: string;
  file: Option;
  created_at: string;
  updated_by: UserStub;
  updated_at: string;
}

export type BlockStub = {
  id: number;
  checklist_template_id: number;
  question_text: string;
  question_type: string;
  multiple_choice_options: string[];
};

export type SubmissionStub = {
  id: number;
  time_period: string;
  time_period_display: string;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
};

export type GenericReference = {
  id: number;
  model: string;
  name: string;
  url: string;
  generic_model_fields: any;
};

export type CommentDetailWithReference = {
  id: number;
  text: string;
  created_at: string;
  user: UserStub;
  reference: GenericReference;
};

export type SubmissionWithContentObject = {
  id: number;
  time_period: string;
  object_id: number;
  reference_object: GenericReference;
};

export type BlockResponse = {
  id: number;
  block: BlockStub;
  response: ResponseStub;
};

export type SubmissionResponse = {
  id: number;
  submission: SubmissionWithContentObject;
  block_responses: BlockResponse[];
};

export type SubmissionDetail = {
  id: number;
  checklist_template: FormTemplateDetail;
  time_period: string;
  object_id: number;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
};

export type BlockDetail = {
  checklist_template_id: number;
  id: number;
  question_text: string;
  question_type: string;
  multiple_choice_options: string[];
  created_by: UserStub;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
  block_response_id?: number;
  file?: Option;
  response?: string;
  additional_comments?: string;
  submissionId?: number;
};

export type FormTemplateStub = {
  id: number;
  name: string;
  group: GroupDetail;
  published_at: string;
  has_submissions?: boolean;
};

export type AllowedSourceTypeStub = {
  id: number;
  source_model_name: string;
};

export type FormTemplateDetail = {
  id: number;
  name: string;
  group: GroupDetail;
  frequency: string;
  block_order: BlockDetail[];
  allowed_source_type: AllowedSourceTypeStub;
  published_at: string;
  deleted_at: string;
  created_by: UserStub;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
};

export type FormTemplate = {
  id: number;
  form_template: FormTemplateDetail;
  time_period: string;
  object_id: number;
  group: GroupDetail;
  created_by: UserStub;
  updated_by: UserStub;
  created_at: string;
  updated_at: string;
};

export type BasicOrganizationDetail = {
  id: number;
  name: string;
  image_url: string;
};

export type BasicPersonDetail = {
  id: number;
  name: string;
  image_url: string;
  title: string;
  organization: OrganizationStub;
};

export type SearchResultOrganization = {
  id: number;
  name: string;
  logo_url: string;
  domain: string;
  description: string;
  country: CountryStub;
};

export type SearchResultPerson = {
  id: number;
  name: string;
  organization: string;
  image_url: string;
};

export type SearchResultMeetingNote = {
  id: number;
  title: string;
  owner: { id: number; name: string; email: string; image_url: string };
};

export type SearchResultOpportunity = {
  id: number;
  name: string;
  owner: { id: number; name: string; email: string; image_url: string };
  opportunity_type: string;
  description: string;
  funnel_stage: string;
  status: string;
};

export type SearchResults =
  | Array<SearchResultPerson>
  | Array<SearchResultOrganization>
  | Array<SearchResultNinjaOrganization>
  | Array<SearchResultMeetingNote>
  | Array<SearchResultOpportunity>;

export interface Option {
  value: number | string;
  label: string;
}

export interface TagDetail {
  id: number;
  name: string;
}

export interface RequestOrganizationConnectionParams {
  subject: string;
  body: string;
}

export interface RequestIndividualConnectionParams {
  subject: string;
  body: string;
  requestee_ids: number[];
}

export interface MeetingNoteEmailParams {
  body: string;
  sendee_ids: number[];
}

export interface ExternalEmailParams {
  subject: string;
  person_ids: number[];
  body: string;
}

export interface EnableOutlookSyncResponse {
  next: string;
}
export interface CreateMeetingNoteParams {
  is_meeting_note: boolean;
  title: string;
  types: string[];
  owner: number;
  date: string;
  attendees: number[];
  organizations: number[];
  tags: string[];
  content: string;
  visibility: "public" | "groups" | "private" | undefined;
  noteKind: "meeting" | "general";
  groups: number[];
  attachment_ids: number[];
}

export interface PersonAutocompleteResult {
  label: string;
  value: number;
  organization: string;
  image_url: string;
  email?: string;
}

export interface OrganizationInvestment {
  investor_fund_id: number;
  investee_organization_id: number;
  financing_stage_id: number;
  holding_status: string;
  investment_date: string;
  investment_partners: number[];
}

export interface OrganizationInvestmentDetail {
  id: number;
  investor_fund: FundDetail;
  investee_organization: OrganizationStub;
  financing_stage: FinanceStageDetail;
  holding_status: string;
  investment_date: string;
  investment_partners: InvestmentPartners[];
}

export interface AdditionalInvestor {
  id?: number;
  key?: number;
  financing_stage: Option | null;
  fund_id: Option | null;
  investment_date: string;
  holding_status: Option;
  investment_partners: PersonAutocompleteResult[];
}

export interface InvestmentPartners {
  organization: any;
  id: number;
  name: string;
  image_url: string;
  label?: string;
  value?: number;
}

export interface AutocompletePeopleParams {
  q: string;
  limit?: number;
  zeroth_degree?: boolean;
  organization_pks?: string;
}

export interface OrganizationAutocompleteResult {
  label: string;
  value: number | string;
  domain: string;
  image_url: string;
  country?: CountryStub;
  description?: string;
  isNinjaOrg?: boolean;
}

export interface CountryStub {
  id: number;
  label: string;
  value: string;
  alpha3: string;
}

export interface InvestorOrganizationResult {
  label: string;
  value: number;
  domain: string;
  image_url: string;
}

export interface UserAutocompleteResult {
  label: string;
  value: number;
  email?: string;
  url?: string;
  id?: number;
  name?: string;
  image_url?: string;
}

export interface WorkTaskParams {
  title: string;
  due_date: string;
  assigned_to: number;
  completed_at?: string;
}

export interface GroupAutocompleteResult {
  label: string;
  value: number;
}

export interface TagAutocompleteResult {
  label: string;
  value: number;
}

export interface FundAutocompleteResult {
  label: string;
  value: number;
}

export interface FunnelStageAutocompleteResult {
  label: string;
  value: string;
}

export interface MeetingNote {
  id: number;
  title: string;
  owner: {
    id: number;
    name: string;
    email: string;
    image_url: string;
    person?: number;
  };
  owner_person: PersonDetail;
  types: string[];
  timestamp: string;
  date: string;
  content: string;
  content_html: string;
  tags: AutocompleteResult[];
  opportunities: OpportunityAutocompleteResult[];
  organizations: OrganizationAutocompleteResult[];
  attendees: PersonAutocompleteResult[];
  kind: NoteKind;
  attachments: AutocompleteResult[];
  access_policy: AccessPolicy | null;
  updated_at: string;
  processed_at: string;
}

export interface OpportunityStub {
  id: number;
  name: string;
  model: string;
  updated_at: string;
  rejected_at: string;
}

export interface MeetingNoteStub {
  id: number;
  title: string;
  date: Date;
  types: string[];
  updated_at: string;
  kind: string;
  owner: UserStub;
  opportunities: OpportunityStub[];
  attachments: AutocompleteResult[];
  organizations: OrganizationStub[];
  attendees: ContactDetail[];
  tags: Tag[];
}

export interface OpportunityAutocompleteResult {
  label: string;
  value: number;
  opportunity_type: string;
  funnel_stage: string;
  organizations: OrganizationAutocompleteResult[];
  types?: [string];
  created_at?: string;
  owner?: UserStub;
  status?: string;
}

export interface OpportunityGTMProject {
  id: number;
  name: string;
  opportunity: OpportunityDetail;
  funnel: string;
  lastActivity: string;
  owner: any;
  status: string;
  latest_comment: LastComment;
}

export interface SpecialitiesAutocompleteResult {
  label: string;
  value: number;
}

export interface TechnologiesDetail {
  label: string;
  value: number;
}

export interface InvestmentFundsAutocompleteResult {
  label: string;
  value: number;
}

export interface InvestorOrganization {
  id: number;
  name: string;
  label: string;
  value: number;
  domain: string;
  image_url: string;
  vertex_relation: string;
}

export interface CreateOpportunityParams {
  name: string;
  description: string;
  opportunity_type: number;
  source_organization: number | string;
  target_organization: number | string | null;
  owner: number;
  funnel_stage: number;
  visibility: Visibility;
  groups: number[];
  rejected_at?: Date | null;
  rejected_reason?: string;
}

export interface AgentPersonaParams {
  name: string;
  persona: string;
  tools: string[];
  visibility: string | undefined;
  groups: number[];
  users: number[];
}

export interface TemplatePromptParams {
  title: string;
  prompt: string;
}

export type PersonaTool = {
  name: string;
  display: string;
  description: string;
};

export type AgentPersonaStub = {
  id: number;
  name: string;
  persona: string;
  default_val: boolean;
  pinned: boolean;
};

export type AgentPersona = {
  id: number;
  name: string;
  persona: string;
  default_val: boolean;
  tools: PersonaTool[];
  access_policy: AccessPolicy | null;
  created_by: UserStub;
  created_at: string;
  updated_by: UserStub;
  updated_at: string;
  pinned: boolean;
};

export type TemplatePrompt = {
  id: number;
  title: string;
  prompt: string;
  agent_persona: AgentPersona;
  created_by: UserStub;
  created_at: string;
  updated_by: UserStub;
  updated_at: string;
};

export interface CreateContactParams {
  image: File | undefined;
  person: {
    first_name: string;
    last_name: string;
    title: string;
    email: string;
    phone: string;
    organization: OrganizationAutocompleteResult | null;
    linkedin: string;
    country: string;
    city: string;
    tags: Option[];
  };
}

export interface CreateGTMProjectParams {
  name: string;
  owner: number;
  owner_groups: number[];
  team_members: number[];
  organization: number;
  organization_contacts: number[];
  visibility: Visibility;
  groups: number[];
  users: number[];
  description: string;
}

export interface GetActivityLogsParams {
  page: number;
  limit: number;
}

export interface GetNinjaLastNewsParams {
  page: number;
}

export interface GetGroupOpportunityParams {
  [x: string]: any;
  groups?: string;
  page: number;
  limit: number;
  status?: string;
  sort?: string;
  q?: string;
  opportunity_types?: number | string;
  owners?: string;
  organizations?: string;
  last_activity?: string;
  latest_comment?: string;
  investors?: string;
  stages?: string;
  tags?: string;
  partners?: string;
  team_members?: string;
  contacts?: string;
  created_at?: string;
  last_activity_since_range?: any;
  created_date_range?: any;
  in_team?: boolean;
  additional_fields?: any;
  filterModel?: { [key: string]: any };
  columnModel?: ColumnState[];
  interaction_date?: string;
}

export interface GetOrganizationFilesParams {
  page: number;
  limit: number;
}

export interface GetEmployeeConnectionsParams {
  page: number;
  limit: number;
  sort?: string;
  organization?: string;
  connection_type?: string;
  title?: string;
  industries?: string;
  refetch?: boolean;
}

export interface GetCorporatesParams {
  page: number;
  limit: number;
  q?: string;
  opp_type_id?: string;
  sort?: string;
  countries?: string;
  industries?: string;
}

export interface GetGtmProjectsParams {
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  organizations?: string;
  updated_at?: string;
  project_lead?: string;
  team_members?: string;
  closed_on?: string;
  status?: string;
}

export interface GetPeopleParams {
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  organizations?: string;
  past_organizations?: string;
  last_activity?: string;
  titles?: string;
  departments?: string;
  organization_countries?: string;
  industries?: string;
  technologies?: string;
  tags?: string;
  person_countries?: string;
  cities?: String;
  connections?: string;
  created_at?: string;
}

export interface GetEmployeesParams {
  page: number;
  limit: number;
  sort?: string;
  title?: string;
  department?: string;
}

export interface GetOrganizationsParams {
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  industries?: string;
  technologies?: string;
  last_activity?: string;
  connections?: string;
  organization_countries?: string;
  tags?: string;
}
export interface GetPortfolioParams {
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  groups?: string;
  owners?: string;
  health?: string;
  tags?: string;
  countries?: string;
  status?: string;
  technologies?: string;
  exited_at?: string;
  fiscal_start_month?: string;
  investment_funds?: string;
}

export interface GetTasksParams {
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  created_by?: string;
  assigned_to?: string;
  created_at?: string;
  updated_at?: string;
  due_date?: string;
  completed_at?: string;
  status?: string;
  opportunity_type?: string;
}

export interface CollabThreadParams {
  title: string;
  content: string;
  resolved_at?: string | null;
}

export interface BaseParams {
  page: number;
  limit: number;
}

export interface GetAllCollabThreadParams extends BaseParams {
  q?: string;
  sort?: string;
  status?: string;
}

export interface GetMeetingNotesParams {
  page: number;
  limit: number;
  sort?: string;
  q?: string;
  kinds?: string;
  types?: string;
  last_updated?: string;
  note_date?: string;
  owners?: string;
  opportunities?: string;
  organizations?: string;
  tags?: string;
  attendees?: string;
}

export interface OrganizationStub {
  id: number;
  uuid: string;
  name: string;
  domain: string;
  image_url: string;
  description: string;
  country?: CountryStub;
  is_vpc?: boolean;
}

export interface OrganizationContactStub {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  title: string;
  updated_at: string;
  organization: OrganizationStub;
  address: string;
  image_url: string;
  is_email_visible: false;
}

export type PersonDetail = {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  title: string;
  updated_at: Date;
  organization: OrganizationStub;
  past_organizations: OrganizationStub[];
  address: string;
  image_url: string;
  is_email_visible: boolean;
  linkedin: string;
  tags: Tag[];
  is_user: boolean;
  country: CountryStub;
  city: string;
  latest_comment?: LastComment;
};

export type JobTitles = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export type VertexNetworkConnectionStub = {
  organization: OrganizationStub;
  vertex_organization: OrganizationStub;
  owner: UserStub;
  job_titles: JobTitles[];
  created_at: string;
  updated_at: string;
};

export type Connection = {
  id: number;
  name: string;
  organization_id: number;
  organization: string;
  title: string;
  image_url: string;
  source_sansan: boolean;
  source_meetings: boolean;
  source_email: boolean;
  source_linkedin: boolean;
  industries: string[];
};

export type Employee = {
  id: number;
  name: string;
  department: string;
  title: string;
  last_activity: Date;
  image_url: string;
  connections: EmployeeConnection[];
};

export type EmployeeConnection = {
  id: number;
  name: string;
  image_url: string;
  is_active: boolean;
};

export type ContactDetail = {
  id: number;
  name: string;
  image_url: string;
};

export type PaginatedList<T> = {
  items: T[];
  limit: number;
  total: number;
  page: number;
  pages: number;
};

export type ActivityLog = {
  actor: UserStub;
  changes: Dictionary<string>;
  timestamp: string;
  action?: string;
  resource?: string;
  object_url?: string;
  reference?: any;
};

export type AdvancedSearchResultPerson = {
  id: number;
  name: string;
  organization: string;
  organization_id: number;
  title: string;
  industries: AutocompleteResult[];
  country: CountryStub;
  city: string;
  last_activity: string;
  image_url: string;
  connections: EmployeeConnection[];
  technologies: AutocompleteResult[];
  tags: Tag[];
  latest_comment?: LastComment;
  organization_country: CountryStub;
};

export type AdvancedSearchResultOrganization = {
  id: number;
  name: string;
  industries: AutocompleteResult[];
  country: CountryStub;
  description: string;
  logo_url: string;
  last_activity: string;
  connections: EmployeeConnection[];
  technologies: AutocompleteResult[];
  employees: Employee[];
  tags: Tag[];
  latest_comment?: LastComment;
};

export type AutocompleteResult = {
  value: number;
  label: string;
};

export type AttachmentDetail = {
  value: number;
  label: string;
};

export type OutlookSyncJob =
  | { state: "pending" }
  | { state: "in_progress"; started_at: string }
  | { state: "success"; started_at: string; ended_at: string }
  | { state: "error"; started_at: string; ended_at: string };

export type OutlookSyncStatus = {
  enabled: boolean;
  last_job?: OutlookSyncJob;
};

export type SearchHistoryPerson = {
  person: SearchResultPerson;
  type: "person";
};

export type SearchHistoryOrganization = {
  organization: SearchResultOrganization;
  type: "organization";
};

export type SearchHistoryText = {
  type: "text";
  text: string;
};

export type SearchHistoryMeetingNote = {
  meeting_note: any;
  opportunity: SearchResultMeetingNote;
  type: "meeting_note";
};

export type SearchHistoryOpportunity = {
  opportunity: SearchResultOpportunity;
  type: "opportunity";
};

export type SearchHistory =
  | SearchHistoryPerson
  | SearchHistoryText
  | SearchHistoryOrganization
  | SearchHistoryMeetingNote
  | SearchHistoryOpportunity;

export type Success<T = {}> = {
  success: boolean;
  data: T;
};

export type Error<T = {}> = {
  success: boolean;
  error: { code: string; message: string };
  data: T;
};

export type PydanticFieldError = {
  ctx: object;
  loc: string[];
  msg: string;
  type: string;
};

export type PydanticError = {
  detail: PydanticFieldError[];
};

export type UpdateSearchHistoryParams =
  | {
      type: "person";
      id: number;
    }
  | {
      type: "organization";
      id: number;
    }
  | {
      type: "opportunity";
      id: number;
    }
  | {
      type: "meeting_note";
      id: number;
    }
  | { type: "text"; text: string };

export type Visibility = "public" | "groups" | "private" | undefined;

export type NoteKind = "meeting" | "general";

export type Group = {
  id: number;
  name: string;
};

export type Tag = {
  id: number;
  name: string;
};

export interface ActivityParticipant {
  name: string;
  type: string;
  id: number;
  image?: string;
}

export interface GenericActivity {
  id: number;
  actor: ActivityParticipant;
  object?: ActivityParticipant;
  target?: ActivityParticipant;
  verb: string;
  particle: string;
  timestamp: string;
}

export type SearchResultNinjaOrganization = {
  country: CountryStub;
  description: string;
  domain: string;
  kind: string;
  uuid: string;
  name: string;
  logo_url: string;
};

export type NinjaItem = {
  name: string;
};

export type NinjaOrganizationDetail = {
  name: string;
  uuid: string;
  headquarterCountry: string;
  domain: string;
  description: string;
  specialities: Array<NinjaItem>;
  linkedin_url: string;
  crunchbase_url: string;
  angellist_url: string;
  facebook_url: string;
  twitter_url: string;
  industry: NinjaItem;
  technologies: Array<NinjaItem>;
  logo_url: string;
  founded_year: number;
  kind: string;
  news: LastNews[];
  patents: Patents[];
  raised_rounds: InvestmentRound[];
  investors: Investor[];
  employee_range: NinjaRange;
  employee_chart: NinjaDataPoint[];
  industries: NinjaItem[];
  has_promising_founder?: boolean;
  has_strong_founder?: boolean;
  has_super_founder?: boolean;
  job_openings?: number;
  website_traffic_estimates_chart: NinjaDataPoint[];
  app_downloads_android_chart: NinjaDataPoint[];
  dealroom_signal?: DealroomSignalDetails;
  followers: number;
};

export type DealroomSignalDetails = {
  completeness?: number;
  growth_rate?: number;
  rating?: number;
  team_strength?: number;
  timing?: number;
};

export type NinjaRange = {
  min: number;
  max: number;
};

export type NinjaDataPoint = {
  date: string;
  value: number;
};

export type InvestmentRound = {
  uuid: string;
  name: string;
  year: string;
  month: string;
  usd_amount: number;
  round_kind: string;
  is_verified: boolean;
  investors: Investor[];
  investment_date: string;
};

export type Investor = {
  uuid: string;
  name: string;
};

export type LastNewsResponse = {
  data: LastNews[];
  page: number;
  total: number;
};

export type LastNews = {
  publication_date: string;
  source: string;
  title: string;
  url: string;
  uuid: string;
};

export type PatentsResponse = {
  data: Patents[];
  page: number;
  total: number;
};

export type FundingRoundsResponse = {
  data: InvestmentRound[];
  page: number;
  total: number;
};

export type Patents = {
  title: string;
  url: string;
  abstract: string;
  priority_date: string;
  cited_by: string[];
  publication_numbers: string[];
};

export type AdditionalFields = {
  type: string;
  title: string;
  properties: {
    source: {
      $ref: string;
    };
    round_size: {
      type: string;
      title: string;
    };
    series_round: {
      $ref: string;
    };
  };
};

export type AdvancedSearchOpportunity = {
  groups: string[];
  opportunity_types: string[];
  owners: string[];
  last_activity: [string, string];
  rejected: boolean;
};

export type OpportunityType = {
  id: number;
  name: string;
  funnel: FunnelStage[];
  has_destination_organization: boolean;
  additional_fields: any | null;
  created_at: string;
};

export type FunnelStage = {
  id: number;
  name: string;
  order: number;
};

export interface AccessPolicy {
  groups: Group[];
  users: UserStub[];
}

export interface PortfolioOrganizationItem {
  group: Group;
  id: number;
  organization: OrganizationDetail;
  owner: UserStub;
  health: "red" | "yellow" | "green";
  currency: string;
  fiscal_start_month: number;
  tags: Tag[];
  latest_comment: LastComment;
  status: string;
  exited_at: string | null;
  exited_description: string;
  investment_fund: FundStub[];
}

export interface CreatePortfolioParams {
  organization: number | string;
  owner: number;
  group: number;
}

export interface FunnelStageParams {
  funnel_stage: number;
  funnel_stage_date: string | undefined;
}

export interface SimilarOrganizationDetail {
  domain: string;
  summary: string;
  country: string;
  uuid: string;
  founded_year: number;
  logo_url: string;
  name: string;
}

export type OpportunityFilter = Partial<{
  owners: Option[];
  last_activity: [string, string];
  status: Option[];
  groups: Option;
  to_revisit: Option;
  investors: Option[];
  stages: Option[];
  tags: Option[];
  filterMB: boolean;
}>;

export type PagingObject = {
  pageIndex: number;
  pageSize: number;
};

export type AiVpc = {
  response: string;
  prompt: string;
  cached: boolean;
};

export type AiCorporate = {
  response: string;
  prompt: string;
  cached: boolean;
};

export type ValChatMessage = {
  type: string;
  content: string;
};

export type ValChat = {
  id: number;
  agent_persona: AgentPersonaStub;
  attachments: Option[];
  last_response: string;
  messages: ValChatMessage[];
  owner: UserStub;
  updated_at: string;
};

export type ValModel = {
  llm_model_name: string;
  name: string;
};

export type OpportunityTableProps = {
  opportunityView?: string;
  data?: PaginatedList<OpportunityDetail>;
  onChange: (state: TableState) => void;
  mutate?: KeyedMutator<PaginatedList<OpportunityDetail>>;
  initialStateTable: {};
  pagingWithLoadMore?: boolean;
  onLoadMore?: (pageIndex: number, pageSize: number) => void;
  initialPageLoadMore?: PagingObject;
  customContainerStyle?: any;
  stateFilter?: TableStateFilter | null;
};

export type PipelineCardProps = {
  item: OpportunityDetail;
  mutate?: KeyedMutator<PaginatedList<OpportunityDetail>>;
};

export type TableStateParams = Partial<{
  pageSize: number;
  pageIndex: number;
  sortBy: Array<SortingRule<string>>;
}>;

export interface EmailParams {
  email: string;
}

export interface EmailDetails {
  id: number;
  address: string;
}

export type CustomersPartners = {
  id: number;
  user: {
    email: string;
    id: number;
    image_url: string;
    name: string;
  };
  created_at: string;
  relation: string;
  destination_type: string;
  destination: {
    id: number;
    name: string;
    model: string;
    image_url: string;
    uuid: string;
  };
};

export interface CreateOrganisationParams {
  source_id: string | number;
  relation: string;
  source_type: string;
  destination_type: string;
  destination_id: string | number;
}

export interface FunnelCount {
  id: number;
  name: string;
  stages: Stage[];
  count: number;
}

export interface Stage {
  id: number;
  name: string;
  count: number;
}

export interface OwnerCounts {
  count: number;
  user: UserStub;
}

export interface OrganizationCounts {
  count: number;
  organization: OrganizationStub;
}

export interface DashboardOppportunityDetail {
  funnel_counts: FunnelCount[];
  owner_counts: OwnerCounts[];
  organization_counts: OrganizationCounts[];
  investor_counts: OrganizationCounts[];
  activity_counts: FunnelCount[];
  company_count: number;
}

export interface StatementType {
  id: number;
  name: string;
}

export interface CreateMetricValueParams {
  portfolio_organization: number;
  metric: number;
  quarter: string;
  value: number | string | null;
  currency: string | null;
}

export interface FinancialMetricDetail {
  id: number;
  name: string;
  description: string;
  parent: FinancialMetricDetail;
  metricType: string;
}

export interface CreateMetricParams {
  financial_statement_type: number;
  name: string;
}

export interface FinancialMetricArrayValues {
  id: number;
  title: string;
  type: string;
}

export interface UpdateMetricsTemplate {
  portfolio_organization: number;
  financial_statement_type: number;
  metrics: number[];
}

export type ShareNote = {
  buttonShare: string;
  pk: number;
  meetingNote: MeetingNote;
};

export interface FunnelDetail {
  portfolio_organization_id: number;
  total_investment_cost: number;
  total_fair_value: number;
  current_ownership: number;
  investment_highlights: InvestmentHighlights[];
  last_updated_date: Date;
}

export interface InvestmentHighlights {
  id: number;
  portfolio_organization_id: number;
  round: string;
  original_investment_date: Date;
  investment_cost: {
    amount: number;
    currency: string;
  };
  shares: string;
  security_type: string;
  post_round_ownership: number;
  total_round_size: {
    amount: number;
    currency: string;
  };
  pre_money_valuation: {
    amount: number;
    currency: string;
  };
  post_money_valuation: {
    amount: number;
    currency: string;
  };
  investors: string;
}

export interface CapTableRowDetail {
  id: number;
  shareholder: string;
  shares_held: number;
  percentage: number;
  round_shares: Record<string, number>;
}

export interface CapTableDetail {
  id: number;
  name: string;
  date: Date;
  rows: CapTableRowDetail[];
  round_shares_cols: string[];
}

export interface UpdatesParams {
  portfolio_organization: number;
  content: string;
  quarter: string;
}

export interface Updates {
  content: string;
  date: Date;
  id: number;
  quarter: string;
}

export interface OrganizationTicker {
  organization: OrganizationStub;
  ticker: Record<string, string | number>;
}

export interface CreateFileParams {
  file: File;
  params: {
    portfolio_organization: number;
  };
}

export interface fileDetail {
  id: number;
  filename: string;
  created_at: string;
  owner: {
    id: number;
    name: string;
    email: string;
    image_url: string;
    person: number;
  };
}

export interface CreateTableStateParams {
  table_name: string;
  state_name: string;
  table_state?: {
    filterModel: { [key: string]: any };
    columnModel: [{ [key: string]: any }];
  };
  visibility: Visibility;
  users: number[];
  groups: number[];
  default: boolean;
  pinned: boolean;
}

export interface AgGridColumnFilterState {
  filterModel: { [key: string]: any };
  columnModel: ColumnState[];
}

export interface TableStateFilter {
  id: number;
  table_name: string;
  state_name: string;
  created_at: string;
  updated_at: string;
  created_by: UserStub;
  updated_by: number;
  is_default: boolean;
  is_pinned: boolean;
  access_policy: AccessPolicy | null;
  table_state_order: number | null;
}

export interface UpdateTableStateOrderParams {
  table_name: string;
  table_state_order: number[];
}

export interface TableStateOrder {
  table_name: string;
  table_state_order: number[];
}

export interface opportunityCellRendererParams {
  data: OpportunityTableDetail;
}

export type Settings = {
  user: UserStub;
  watchlist_notification: boolean;
  daily_notification: boolean;
  due_within_threshold: number;
  weekly_inactive_opp_notification: boolean;
  weekly_inactive_opp_threshold: number;
  weekly_collab_request_digest_notification: boolean;
};

export type GroupFollowSettings = {
  organization: OrganizationStub;
  follow: boolean;
};

export interface NotificationData {
  watchlist_notification?: boolean;
  daily_notification?: boolean;
  due_within_threshold?: number;
  weekly_inactive_opp_notification?: boolean;
  weekly_inactive_opp_threshold?: number;
  weekly_collab_request_digest_notification?: boolean;
}

export interface getOnePagerContentParams {
  corporate_pk?: string | number;
  vpc_pks: string;
}

export interface downloadOnePagerParams {
  html_str: string;
}

export interface generativeAIParams {
  prompt: string;
  comment_pks: string;
  free_text: boolean;
  visibility?: Visibility;
  users?: number[];
  groups?: number[];
  pk: number;
}

export interface generativeAIVPCParams {
  query: string;
  visibility?: Visibility;
  users?: number[];
  groups?: number[];
}

export interface generativeAICorporateParams {
  query: string;
  visibility?: Visibility;
  users?: number[];
  groups?: number[];
}

export interface TinyMCEEditorProps {
  value: any;
  handleEditorChange: (stringifiedHtmlValue: string) => void;
  handleEditorAction: () => void;
}

export interface EditorResponse {
  data: string;
}

export interface GetOrganizationMeetingNotesParams {
  page: number;
  limit: number;
  sort?: string;
  q?: string;
  meeting_type?: string;
  owner?: string;
  tags?: string;
  counterparty?: string;
  date?: string;
  filterMB?: boolean;
}

export interface MergeOrganizationsParams {
  remove: number[];
}

export interface MergePersonsParams {
  remove: number[];
}

export interface QuarterlyFinancialMetricsValue {
  id: number;
  value: string;
  qtr: string;
}
export interface FinancialMetric {
  id?: number;
  name: string;
  metric_type?: string;
  order?: number;
  values?: QuarterlyFinancialMetricsValue[];
}

export interface QuarterlyUpdates {
  id: number;
  content: string;
  quarter: string;
}

export interface FinancialUpdates {
  organization: {
    id: number;
    organization: OrganizationStub;
    currency: string;
    fiscal_start_month: number;
    reference_month: number;
  };
  investment_fund: FundStub[];
  quarterly_financial_metrics: FinancialMetric[];
  quarterly_updates: QuarterlyUpdates[];
}

export type FinancialMetricSummary = {
  id?: number;
  name: string;
  value?: string | number | null;
  metric_type?: string;
  order?: number;
  values?: QuarterlyFinancialMetricsValue[];
};

export type FinancialUpdatesSummary = {
  organization: {
    id: number;
    organization: OrganizationStub;
    currency: string;
    fiscal_start_month: number;
    reference_month: number;
  };
  investment_fund: FundStub[];
  quarterly_financial_metrics: FinancialMetricSummary[];
  quarterly_updates: QuarterlyUpdates[];
  quarterly_updates_content: string;
  quarter_year: string;
  quarter: string;
  year: number;
};

export interface MergeOpportunitiesParams {
  remove: number[];
}

export interface InvestmentFundParams {
  name: string;
  vertex_group_pk: number;
}

export interface ApolloUnknownPersonParams {
  first_name?: string;
  last_name?: string;
  name?: string;
  organization_name?: string;
  organization_domain?: string;
  email?: string;
}

export interface ApolloPersonDetail {
  id: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  title?: string;
  organization_name?: string;
  organization?: OrganizationAutocompleteResult | null;
  image_url?: string;
  image_file?: File | undefined;
  linkedin?: string;
  country?: CountryStub;
}

export type ContactFormValues = {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone: string;
  organization: OrganizationAutocompleteResult | null;
  past_organizations: OrganizationAutocompleteResult[];
  image_url: string;
  image: File | undefined;
  is_email_visible: boolean;
  tags: TagAutocompleteResult[];
  linkedin: string;
  country: CountryStub | null;
  city: string;
  email_unknown?: boolean;
};

export interface LeadSourceStub {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export type DealSourcingActionType = {
  action: "approve" | "revisit" | "reject" | "";
};

export type ValEnrichment = {
  id: number;
  score: number;
  reason: string;
  category: string;
  created_at: Date;
  updated_at: Date;
  prompt: string;
};

export type CoreSignalEnrichment = {
  id: number;
  coresignal_id: string;
  name: string;
  url: string;
  website: string;
  size: string;
  industry: string;
  description: string;
  followers?: number;
  founded?: number;
  logo_url: string;
  created: Date;
  last_updated: Date;
  last_response_code?: number;
  type: string;
  employees_count?: number;
  headquarters_country_parsed: string;
  created_at: Date;
  updated_at: Date;
  linkedin_id: string;
};

export type VoteDetail = {
  owner: UserStub;
};

export const dealSourcingPriorityOptions = ["high", "medium", "low", ""];
export type DealSourcingPriorityOptions =
  typeof dealSourcingPriorityOptions[number];

export type ValPriority = {
  id: number;
  score: number;
  reason: string;
  prompt: string;
  created_at: Date;
  updated_at: Date;
};

export type LeadDetail = {
  id: number;
  name: string;
  description: string;
  website: string;
  domain: string;
  group: Group;
  country: CountryStub;
  logo_url: string;
  linkedin: string;
  action_at?: Date;
  action_reason: string;
  action_status: "approve" | "revisit" | "reject" | "hold" | "";
  organization: OrganizationStub;
  organization_opportunities: OpportunityDetail[];
  source: LeadSourceStub[];
  latest_comment?: LastComment;
  created_at: Date;
  updated_at: Date;
  coresignal_enrichment?: CoreSignalEnrichment;
  val_enrichment?: ValEnrichment;
  ninja_id: string;
  linkedin_id: string;
  is_tech_startup: boolean | null;
  score: number;
  score_reason: string;
  revisit_at: string;
  priority: string;
  val_priority: ValPriority;
  owner?: UserStub;
  reviewed: boolean;
  tags: Tag[];
};

export type UpdateLeadParams = {
  name?: string;
  website?: string;
  country?: number;
  linkedin?: string;
  action_reason?: string;
  action_status?: string;
  revisit_at?: Date | null;
  is_tech_startup?: boolean | null;
  priority?: DealSourcingPriorityOptions;
  owner?: number;
  tags?: string[];
};

export interface NumberFilter {
  field_name: string;
  value: number;
  operand: "gte" | "lte" | "gt" | "lt" | "eq" | "=";
}

export interface GetLeadsParams {
  page: number;
  limit: number;
  status?: string;
  sort?: string;
  q?: string;
  country?: string;
  action_at?: string;
  action_status?: string;
  organization?: string;
  source?: string;
  created_at?: string;
  updated_at?: string;
  score?: string;
  category?: string;
  is_tech_startup?: boolean | null | string;
  is_tech_startup_score?: string;
  founded?: string;
  priority?: string;
  val_priority?: string;
  industry?: string;
  owner?: string;
  reviewed?: string;
  tags?: string;
  has_linked_opportunities?: boolean;
}

export interface CoreSignalConfigDetails {
  id: number;
  name: string;
  group: Group;
  created_at: Date;
  updated_at: Date;
  country: string;
  founded: number;
  last_run?: Date;
}

export interface CoreSignalConfigUpdateParams {
  group: number;
  name: string;
  country: string;
  founded: number;
}

export interface GetCoreSignalConfigAllParams {
  page: number;
  limit: number;
  status?: string;
  sort?: string;
  q?: string;
}

export type translateParamsProps = {
  startRow: number | undefined;
  filterModel: { [key: string]: any };
  sortModel: ColumnState[];
  paginationPageSize: number;
  queryString?: string;
};

export type ResponseTemplateParams = {
  time_period?: string | null;
  source_type: string;
};

export type Template = {
  id: number;
  name: string;
  group: Group;
  published_at: Date;
};

export type Submission = {
  id: number;
  time_period?: string;
  object_id: number;
  reference_object: {
    id: number;
    model: string;
    name?: string;
    url?: string;
  };
};

export type SubmissionResponses = {
  id: number;
  submission: Submission;
  block_responses: [BlockResponse];
};

export type Corporates = {
  id: number;
  name: string;
  description: string;
  logo_url: string;
  funnel_metrics: FunnelMetrics[];
  domain: string;
  industries: string[];
  country: CountryStub;
};

export type NinjaCommitmentDetails = {
  uuid: string;
  amount?: number;
  currency?: string;
  amount_usd?: number;
  lp?: LightOrganizationDetails;
  fund?: NinjaFundStub;
};

export type NinjaFundStub = {
  name: string;
  uuid: string;
  amount?: number;
  currency?: string;
  fund_kind?: string;
  amount_usd?: number;
};

export type NinjaLightOrganizationDetails = {
  name: string;
  uuid: string;
  logo_url?: string;
};

export type Investments = {
  items: LightOrganizationDetails[];
  total: number;
};

export type NinjaInvestorDetails = {
  name: string;
  contributors: NinjaCommitmentDetails[];
  deal_size?: string;
  funds: NinjaFundStub[];
  fund_stages: string[];
  investment_stage: string[];
  investment_industries: string[];
  investments: Investments;
  investments_count?: number;
  total_funding?: number;
  commitments: NinjaCommitmentDetails[];
  country_experience: string[];
};

export type NinjaInvestmentRoundDetails = {
  uuid: string;
  name: string;
  year: number;
  month: number;
  usd_amount?: number;
  round_kind?: string;
  is_verified: boolean;
  investors: NinjaLightOrganizationDetails[];
  investment_date: Date;
};

export type NinjaFundDetails = {
  name: string;
  uuid: string;
  year?: number;
  description?: string;
  fund_kind?: string;
  is_closed?: string;
  status?: string;
  currency?: string;
  amount?: number;
  amount_usd?: number;
  investment_industries: string[];
  locations_and_markets: string[];
  portfolio: LightOrganizationDetails[];
  owner?: NinjaLightOrganizationDetails;
  rounds: NinjaInvestmentRoundDetails[];
  contributors: NinjaCommitmentDetails[];
};

export type LightOrganizationDetails = {
  domain?: string;
  country?: CountryStub;
  name: string;
  kind?: string;
  uuid: string;
  description?: string;
  logo_url?: string;
};

export type OpportunityProps = {
  opportunity: OpportunityDetail | undefined;
  mutate?: KeyedMutator<OpportunityDetail>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setErrMessage?: Dispatch<SetStateAction<string>>;
};

export type DetailHeaderPros = {
  opportunity: OpportunityDetail | undefined;
  mutate: KeyedMutator<OpportunityDetail>;
  opportunityType: OpportunityType;
  opportunityTypes: OpportunityType[];
  linkedDeals: OpportunityDetail[];
};

export type FunnelItemProps = {
  funnelState: FunnelStage;
  funnelStageHistory: FunnelStageHistoryItem | undefined;
  isActive: boolean;
};

export type LinkWithAvatarProps = {
  name: string;
  size: number;
  className?: string;
  href: string;
  avatarUrl?: string;
};

export type NinjaProfileProps = {
  organizationDetail: OrganizationDetail;
};

export type OrganizationMeetingNoteCollectionProps = {
  data: Success<PaginatedList<MeetingNote>>[] | undefined;
  size: number;
  setSize: (
    size: number | ((_size: number) => number)
  ) => Promise<Success<PaginatedList<MeetingNote>>[] | undefined>;
};

export type OrganizationMeetingNoteFilters = Partial<{
  meetingType: Option[];
  owner: Option[];
  tags: Option[];
  counterparty: Option[];
  date: [string, string];
  filterMB: boolean;
}>;

export type OrganizationMeetingNoteFiltersProps = {
  id: number;
  filters: OrganizationMeetingNoteFilters;
  setFilters: (filters: OrganizationMeetingNoteFilters) => void;
};

export type MeetingNotesProps = {
  id: number;
  page: "organizations" | "people";
};

export type OrganizationFilesProps = {
  id: number;
};

export type OrganizationOpportunityProps = {
  id: number;
};

export type OrganizationComparablesProps = {
  id: string;
  organizationDetail: OrganizationDetail;
};

export type ComparablesViewProps = {
  id: string;
  organizationDetail: OrganizationDetail;
  isLoading: boolean;
  orgData: SimilarOrganizationDetail[];
  watchList: CustomersPartners[];
  mutate: KeyedMutator<Success<CustomersPartners[]>>;
  isOpenPublicComparables: boolean;
  setIsOpenPublicComparables: Dispatch<SetStateAction<boolean>>;
};

export type OrganizationActivityProps = {
  id: number;
};

export type OrganizationConnectsProps = {
  organization: OrganizationDetail;
};

export type OrganizationProfileProps = {
  organizationDetail: OrganizationDetail;
};

export type OrganizationHeaderProps = {
  organizationDetail: OrganizationDetail;
  mutate: KeyedMutator<Success<OrganizationDetail>>;
};

export type OrganizationExternalLinksProps = {
  organizationDetail: OrganizationDetail;
};

export type OrganizationTabHeader = {
  index: number;
  name: string;
  tab: string;
  className: string;
  disabled?: boolean;
};

export enum OrganizationTab {
  GENERAL,
  PORTFOLIO,
}

export type OrganizationMenuProps = {
  router: NextRouter;
  view: OrganizationTab;
  updateMenuView: (tab: OrganizationTab) => void;
};

export type PeopleConnections = {
  items: EmployeeConnection[];
};

export type PeopleActivityProps = {
  id: number;
};

export type PeopleProfileProps = {
  profileDetail: PersonDetail;
  mutate: KeyedMutator<PersonDetail>;
  setNotFoundError?: Dispatch<SetStateAction<boolean>>;
};

export type TabHeader = {
  id: string;
  opportunityTypeName: string;
  className?: string;
};

export type HeaderTabProps = {
  tab: string;
  name: string;
  className: string;
};

export type OpportunityViewProps = {
  columnDefs: ColDef[];
  paginationPageSize: number;
  opportunityTypeName: string;
  alwaysFilteringBy?: any;
  alwaysFilteringByMobile?: any;
  stateTableName: string;
  pageName: string;
  hasTabMenu?: boolean;
  hideBookmark?: boolean;
  showLoadingData?: boolean;
  showMobileBookmark?: boolean;
};

export type VpcFundParams = {
  name: string;
  organization_id?: string | number;
  fund_investment_id?: string | number;
  lp_fund_ids?: (string | number)[];
};

export type VpcFundResponse = {
  id: number;
  name: string;
  organization: {
    id: number;
    uuid: string;
    name: string;
    domain: string;
    image_url: string;
  };
  lp_funds: FundInvestmentDetail[];
};

export type FundFormValues = {
  id?: number;
  investor?: OrganizationAutocompleteResult;
  fund_name: string;
  parent_fund?: Option[];
};
