import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import remarkGfm from "remark-gfm";

export type Command = {
  title: string;
  description: string;
};

export const replaceDoubleBraces = (str: string) => {
  return str
    .replace(/(?:\r\n|\r|\n)/g, "<br>")
    .replace(/{{(.+?)}}/g, (_, g1) => {
      return `<span class="px-1.5 py-0.5 rounded bg-blue-100 text-blue-700">${g1.trim()}</span>`;
    });
};

export const MarkdownContent = ({
  message,
  copyStatus,
  onCopyHandler,
}: {
  message: any;
  copyStatus: string;
  onCopyHandler: (children: ReactNode) => void;
}) => {
  const languageRegex = /language-(\w+)/;

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        code(props) {
          const { children, className, ...rest } = props;
          const match = className && className.match(languageRegex);
          const language = match ? match[1] : "";
          if (language === "") {
            return (
              <span
                style={{
                  color: "#880000",
                  fontWeight: 500,
                }}
                className="w-fit"
              >
                {children}
              </span>
            );
          }
          return (
            <div className="relative grid my-3">
              {language && (
                <div className="bg-black text-xs text-gray-300 px-1">
                  {language.toUpperCase()}
                </div>
              )}
              <div className="relative">
                <div
                  className="h-4 min-w-[24px] absolute right-1 top-2 z-top cursor-pointer"
                  title="Copy to clipboard"
                  onClick={() => onCopyHandler(children)}
                >
                  {copyStatus ? (
                    <span className="text-xs text-gray-500">{copyStatus}</span>
                  ) : (
                    <ClipboardDocumentIcon className="h-5 w-5 stroke-gray-400 cursor-pointer hover:stroke-gray-500" />
                  )}
                </div>
                <SyntaxHighlighter
                  language={language}
                  PreTag="div"
                  lineProps={{
                    style: {
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                    },
                  }}
                  wrapLines={true}
                  style={atomOneDark}
                >
                  {String(children)}
                </SyntaxHighlighter>
              </div>
            </div>
          );
        },
        table(props) {
          return <table className="table-auto">{props.children}</table>;
        },
        thead(props) {
          return <thead className="bg-gray-100">{props.children}</thead>;
        },
        tbody(props) {
          return <>{props.children}</>;
        },
        tr(props) {
          return <tr className="border-b border-gray-200">{props.children}</tr>;
        },
        td(props) {
          return (
            <td className="border border-gray-200 px-4 py-2">
              {props.children}
            </td>
          );
        },
        th(props) {
          return (
            <th className="border-b border-gray-200 px-4 py-2 text-left font-bold">
              {props.children}
            </th>
          );
        },
        img(props) {
          return (
            <img
              src={props.src}
              className="h-20 w-20"
              alt={props.alt}
              loading="lazy"
            />
          );
        },
      }}
    >
      {message.replace(/<br\s*\/?>/g, " ")}
    </Markdown>
  );
};
