import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";

const SCROLL_DELAY = 10;

export const useScrollToFirstError = (
  formErrors: FormikErrors<any> | undefined,
  submit: boolean,
  setSubmit: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (formErrors && Object.keys(formErrors).length > 0 && submit) {
      const timeout = setTimeout(() => {
        const content = document.getElementById("sidebar-wide-view");
        const errorElements: HTMLCollectionOf<Element> =
          document.getElementsByClassName("border-red-500");
        if (content && errorElements.length > 0) {
          const t =
            content.scrollTop +
            (errorElements[0] as HTMLElement).getBoundingClientRect().top -
            (errorElements[0] as HTMLElement).getBoundingClientRect().height -
            55;
          content.scrollTo({
            top: t,
            behavior: "smooth",
          });
          setSubmit(false);
        }
      }, SCROLL_DELAY);
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);
};
