import {
  ArchiveBoxIcon,
  ClipboardIcon,
  FireIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import DropDownMenu from "./CreateDropDownMenu";
import { Llama } from "./Llama";
import ProfileMenu from "./ProfileMenu";
import QuickSearch from "./QuickSearch";

const Profile = () => {
  return <ProfileMenu navOpen={true} />;
};

const Tooltip = ({ value }: { value: string }) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className="absolute z-top left-0 invisible group-hover:visible top-9 inline-block py-1 px-2 text-3xs  w-max font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip"
    >
      {value}
      <div className="tooltip-arrow" data-popper-arrow></div>
    </div>
  );
};

const Header = () => {
  return (
    <div>
      <div className="flex flex-row space-x-1 md:space-x-4 justify-end mb-2 mr-6 ml-6 md:mr-10 md:ml-10 hidden md:flex">
        <QuickSearch />
        <DropDownMenu />
        <div className="hidden md:flex space-x-2">
          <div className="cursor-pointer">
            <div
              id="ask-val-desktop-button"
              className="group relative"
              onClick={() => {
                window.sessionStorage.setItem("showValStatus", "true");
                window.dispatchEvent(new Event("storage"));
              }}
            >
              <Llama customClass={"w-5 h-5 mr-2"} />
              <Tooltip value="Ask Val" />
            </div>
          </div>
          <Link href={`/collab`}>
            <div className="relative group">
              <ArchiveBoxIcon className="h-6 w-6 stroke-1 text-gray-500 mt-1 relative" />
              <Tooltip value="Collaboration" />
            </div>
          </Link>
          <Link href={`/tasks`}>
            <div className="relative group">
              <ClipboardIcon className="h-6 w-6 stroke-1 text-gray-500 mt-1 relative" />
              <Tooltip value="Tasks" />
            </div>
          </Link>
          <Link href={`/activity_logs`}>
            <div className="relative group">
              <div className="mt-1 w-6 h-6">
                <img
                  src="/activity.svg"
                  alt="Activity Icon"
                  className="w-6 h-6 text-gray-500"
                />
              </div>
              <Tooltip value="Activity log" />
            </div>
          </Link>
          <Link href={`/help`}>
            <div className="relative group">
              <QuestionMarkCircleIcon className="h-6 w-6 stroke-1 text-gray-500 mt-1" />
              <Tooltip value="Help" />
            </div>
          </Link>
          <Link href={`/changelog`}>
            <div className="relative group">
              <FireIcon className="h-6 w-6 stroke-1 text-gray-500 mt-1" />
              <Tooltip value="Change Log" />
            </div>
          </Link>
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default Header;
