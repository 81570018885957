import ReactCountryFlag from "react-country-flag";

type CountryProps = {
  alpha2: string;
  countryName: string;
};

export const Country = ({ alpha2, countryName }: CountryProps) => {
  return (
    <div className="flex flex-row items-center">
      <ReactCountryFlag
        countryCode={alpha2}
        svg
        className="w-6 h-6"
        title="US"
      />
      <div className="ml-2.5">{countryName}</div>
    </div>
  );
};
