import { Popover, Transition } from "@headlessui/react";
import {
  ArrowRightOnRectangleIcon,
  ChevronRightIcon,
  CogIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import router from "next/router";
import { Fragment, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { getter } from "../api";
import * as types from "../api/types";
import { useAuth, useCurrentUser } from "./AuthProvider";
import Avatar from "./Avatar";
import {
  getInitialGlobalGroupSelected,
  setInitialGlobalGroupSelected,
} from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

type ProfileMenuProps = {
  navOpen: boolean;
};
type MenuButtonProps = {
  menuName: string;
  icon: JSX.Element;
  onclickHandler: () => void;
};

type MenuButtonGroupProps = {
  menuName: string;
  icon: JSX.Element;
  onclickHandler: (option: types.Option) => void;
  selectedGroup: types.Option | undefined;
  groupOptions: types.Option[];
};

const ProfileMenu = ({ navOpen }: ProfileMenuProps) => {
  const { signOut } = useAuth();
  const { user: currentUser } = useCurrentUser();
  const iconButtons = {
    settings: <CogIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
    helpCenter: (
      <QuestionMarkCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
    ),
    logout: (
      <ArrowRightOnRectangleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
    ),
    userGroup: <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
  };

  const [selectedGroup, setSelectedGroup] = useState<
    types.Option | undefined
  >();

  const { mutate, cache } = useSWRConfig();

  const { data: groupOptions } = useSWR<types.Option[]>(
    "/api/users/autocomplete/groups",
    getter
  );

  const initialValues = cache.get(GLOBAL_GROUP_KEY);

  useEffect(() => {
    checkGlobalGroupWhenReload(initialValues);

    if (initialValues && groupOptions && groupOptions.length) {
      setSelectedGroup(initialValues);
      mutate(GLOBAL_GROUP_KEY, initialValues);
      setInitialGlobalGroupSelected(initialValues);
    } else if (groupOptions && groupOptions.length) {
      setSelectedGroup(groupOptions[0]);
      mutate(GLOBAL_GROUP_KEY, groupOptions[0]);
      setInitialGlobalGroupSelected(groupOptions[0]);
    }
    // eslint-disable-next-line
  }, [groupOptions, setSelectedGroup, initialValues]);

  const checkGlobalGroupWhenReload = (option: types.Option) => {
    const optionInCache = getInitialGlobalGroupSelected();
    if (!option || option?.value !== optionInCache.value) {
      mutate(GLOBAL_GROUP_KEY, optionInCache);
      setSelectedGroup(optionInCache);
    }
  };

  const MenuButtons = () => {
    return (
      <div className="flex flex-col item-start">
        <div className="md:hidden"></div>
        <MenuButton
          menuName="Settings"
          icon={iconButtons["settings"]}
          onclickHandler={() => router.push("/settings")}
        ></MenuButton>
        <MenuButtonGroups
          menuName="Select Group"
          icon={iconButtons["userGroup"]}
          groupOptions={groupOptions || []}
          selectedGroup={selectedGroup}
          onclickHandler={(option) => setSelectedGroup(option)}
        ></MenuButtonGroups>
        <div className=" md:hidden">
          <MenuButton
            menuName="Help Center"
            icon={iconButtons["helpCenter"]}
            onclickHandler={() => router.push("/help")}
          ></MenuButton>
        </div>
        <MenuButton
          menuName="Log Out"
          icon={iconButtons["logout"]}
          onclickHandler={() => {
            signOut();
            localStorage.removeItem("selectedGroup");
          }}
        ></MenuButton>
      </div>
    );
  };

  const MenuButton = ({ menuName, icon, onclickHandler }: MenuButtonProps) => {
    return (
      <button
        className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-xs hover:bg-blue-500 hover:text-white`}
        onClick={onclickHandler}
      >
        {icon}
        {menuName}
      </button>
    );
  };

  const MenuButtonGroups = ({
    menuName,
    icon,
    onclickHandler,
    selectedGroup,
    groupOptions,
  }: MenuButtonGroupProps) => {
    return (
      <div className="flex flex-col md:flex-row">
        <button
          className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-xs`}
        >
          {icon}
          {menuName}
        </button>
        <select
          id="countries"
          className=" w-[300px] lg:text-xs  bg-gray-50 border border-gray-300 text-gray-900 text-2xs rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={selectedGroup?.value}
          onChange={(e) => {
            const selectedOption = e.currentTarget.selectedOptions[0];
            const option = {
              value: selectedOption.value,
              label: selectedOption.text,
            };
            cache.set(GLOBAL_GROUP_KEY, option);
            mutate(GLOBAL_GROUP_KEY, option);
            onclickHandler(option);
          }}
        >
          {groupOptions?.map((item, index) => (
            <option
              key={item.value}
              className={`${
                selectedGroup?.value === item.value
                  ? "bg-blue-500 text-white"
                  : ""
              }`}
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className={`flex`}>
      <Popover className="relative mx-auto">
        {({ open }) => (
          <div className="flex flex-col items-start">
            <Popover.Button>
              <div className="flex flex-row space-x-3">
                <div className="h-8 w-8">
                  <Avatar
                    name={currentUser.firstName}
                    size={30}
                    className="shrink-0"
                    url={currentUser.image_url}
                  />
                </div>
                <div
                  className={`flex flex-col items-start block md:hidden ${
                    !navOpen && "hidden"
                  }`}
                >
                  <div className={`text-white duration-300`}>
                    {currentUser.name}
                  </div>
                  {selectedGroup && (
                    <div className="flex flex-row text-sm text-white duration-300">
                      <div className="text-start text-xs">
                        {selectedGroup.label}
                      </div>
                      <ChevronRightIcon className="w-4 h-4 stroke-[3px] text-white mt-[3px]" />
                    </div>
                  )}
                </div>
              </div>
            </Popover.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute w-fit md:right-0 md:top-10 -left-6 md:left-auto z-top flex flex-col items-start bg-white border border-gray-200 rounded-xl p-2">
                <MenuButtons />
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ProfileMenu;
