import Avatar from "../Avatar";

export const OrganizationSelectSkeleton = () => (
  <div className="flex gap-x-3 items-center w-full">
    <Avatar
      name=""
      size={28}
      className="rounded-none shrink-0 hidden xl:flex animate-pulse"
    />
    <div className="grid gap-y-2">
      <div className="h-4 w-60 rounded-sm bg-gray-200 animate-pulse"></div>
      <div className="h-3 w-60 rounded-sm bg-gray-200 animate-pulse"></div>
    </div>
  </div>
);
export default OrganizationSelectSkeleton;
